import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import Cookies from 'js-cookie'
import axios from 'axios';
import Web3 from 'web3';
import { PBMC_ABI } from '../../../contract/ERC20_ABI'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updatePBMCValue } from '../../../redux/reducer';
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { useForm } from "react-hook-form";
import { useGetInvoiceChargeQuery } from '../../../redux/InvoiceChargeAPI';

function Proceed_modal({ show, setShow, invoiceData, reset, setFilePreview, buyerId, slug, editBuyerData }) {
  const { data, isLoading } = useGetInvoiceChargeQuery()

  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const togglePBMCAmount = useSelector(state => state.user.updatePBMCAmount)
  const WalletAddress = useSelector(a => a.walletBalance.walletAddress)
  const [cardShow, setCardShow] = useState(false)
  const [loginData, setLoginData] = useState(null)
  const accessToken = sessionStorage.getItem('accessToken')
  const loginUserData = sessionStorage.getItem('loggedInUserData')
  const [allInvoice, setAllInvoice] = useState([])
  const [loading, setLoading] = useState(false)
  const [PBMCloading, setPBMCLoading] = useState(false)
  const [paymentType, setPaymentType] = useState("")

  //================ bank details modal ====================
  const [bankName, setBankName] = useState("")
  const [currency, setCurrency] = useState("")
  const [accountNum, setAccountNum] = useState("")
  const [correspondent, setCorrespondent] = useState("")
  const [bankAddress, setBankAddress] = useState("")
  const [beneficiary, setBeneficiary] = useState("")
  const [IBAN, setIBAN] = useState("")
  const [swift, setSwift] = useState("")
  const [transactionLoading, setTransactionLoading] = useState(false)
  const [showBankDetail, setShowBankDetail] = useState(false)

  const handleCloseBankDetail = () => {
    setShowBankDetail(false)
    setShow(false)
  }
  //================ bank details modal ====================

  //========= get invoice charge api start =======
  const [invoiceCharges, setInvoiceCharges] = useState([])
  const getInvoiceCharges = async () => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getInvoiceCharege`,
    ).then((res) => {
      setInvoiceCharges(res.data.getinvoiceCharge)
    })
      .catch((err) => {
        console.log("invoice charge res err", err)
      })
  }

  useEffect(() => {
    getInvoiceCharges()
  }, [])
  //========= get invoice charge api end =======


  useEffect(() => {
    if (loginUserData) {
      setLoginData(JSON.parse(loginUserData))
    }
  }, [])

  const handleClose = () => {
    setShow(false)
    setLoading(false)
  }

  let defaultValues = {}
  defaultValues.buyer_contact = ""
  defaultValues.id_no = ""
  defaultValues.buyer_email = ""


  const [chargeAmount, setChargeAmount] = useState({})

  const handleFiat = (paymentType) => {

    setPaymentType(paymentType)
    if (paymentType === 'fiat') {
      setShowBankDetail(true)
      setShow(false)
      const saveData = invoiceCharges.find((ele) => ele.iso_code === invoiceData.iso_code)
      let symbol = saveData.invoiceChargesymbole
      let amount = saveData.invoiceChargeAmount
      let currency = saveData.invoiceChargeCurrency
      setChargeAmount({ amount, symbol, currency })
    }


    if (invoiceData.iso_code === "USD") {
      setBankName("INVESTEC BANK LIMITED")
      setBeneficiary("PBM INVESTOR SERVICES SA")
      setSwift("IVESZAJJ")
      setCurrency("USD")
      setAccountNum("1300224290504")
      setCorrespondent("CITIBANK NEW YORK")
    }
    if (invoiceData.iso_code === "EUR") {
      setBankName("INVESTEC BANK LIMITED")
      setBeneficiary("PBM INVESTOR SERVICES SA")
      setSwift("IVESZAJJ")
      setCurrency("EUR")
      setAccountNum("1300224290502")
      setCorrespondent("COMMERZBANK FRANKFURT")
    }
    if (slug) {
      let formData = new FormData()
      formData.append("invoiceCompany", invoiceData.company_name)
      formData.append("invoiceAmount", Number(invoiceData.invoice_amount))
      formData.append("invoiceNumber", invoiceData.invoice_number)
      formData.append("invoiceLocation", invoiceData.location)
      formData.append("invoiceDate", invoiceData.date)

      if (invoiceData.attachment_file) {
        formData.append("document_image", invoiceData.attachment_file[0])
      }

      formData.append("authId", loginData._id)
      if (!buyerId) {
        formData.append("buyerId", editBuyerData.buyerId)
        formData.append("invoiceBuyer_Name", editBuyerData.buyerName)
      } else {
        formData.append("buyerId", buyerId._id)
        formData.append("invoiceBuyer_Name", buyerId.invoiceBuyer_Name)
      }

      formData.append("invoiceBuyer_Contact", invoiceData.buyer_contact)
      formData.append("invoiceBuyer_RegistrationNo", invoiceData.id_no)
      formData.append("invoiceBuyer_Address", invoiceData.buyer_address)
      formData.append("buyer_email", invoiceData.buyer_email)
      formData.append("company_email", invoiceData.company_email)
      formData.append("walletAddress", WalletAddress)
      formData.append("id", slug)

      if (paymentType === 'PBMC') {
        formData.append("iso_code", invoiceData.iso_code)
      }
      formData.append("payment_method", paymentType)

      for (const value of formData.values()) {
      }

      if (paymentType === 'PBMC') {
        if (WalletAddress !== "NA") {
          (async function () {
            setPBMCLoading(true)
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x1' }],
              });
            } catch (error) {
              console.error('Failed to switch network:', error);
            }

            const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
            try {
              const web3 = new Web3(window.ethereum);
              const token = new web3.eth.Contract(PBMC_ABI, contractAddress);
              const userAccount = WalletAddress
              const pbmValue = toWei("20").toString()
              const tx = await token.methods.transfer(process.env.REACT_APP_OWNER_ADDRESS, pbmValue).send({ from: userAccount })
              formData.append("transactionHash", tx.transactionHash)
              reset({ ...defaultValues })
              setFilePreview(null)
              dispatch(updatePBMCValue(!togglePBMCAmount))
              updateapi(formData)

            } catch (err) {
              setPBMCLoading(false)
              console.log(err);
            }
          })()
        } else {
          dispatch(updatePBMCValue(!togglePBMCAmount))
        }
      }

    } else {


      let formData = new FormData()
      formData.append("invoiceCompany", invoiceData.company_name)
      formData.append("invoiceAmount", Number(invoiceData.invoice_amount))
      formData.append("invoiceNumber", invoiceData.invoice_number)
      formData.append("invoiceLocation", invoiceData.location)
      formData.append("invoiceDate", invoiceData.date)
      formData.append("document_image", invoiceData.attachment_file[0])
      formData.append("authId", loginData._id)
      formData.append("buyerId", buyerId._id)
      formData.append("invoiceBuyer_Name", buyerId.invoiceBuyer_Name)
      formData.append("invoiceBuyer_Contact", invoiceData.buyer_contact)
      formData.append("invoiceBuyer_RegistrationNo", invoiceData.id_no)
      formData.append("invoiceBuyer_Address", invoiceData.buyer_address)
      formData.append("buyer_email", invoiceData.buyer_email)
      formData.append("company_email", invoiceData.company_email)
      formData.append("walletAddress", WalletAddress)

      if (paymentType === 'PBMC') {
        formData.append("iso_code", invoiceData.iso_code)
      }
      formData.append("payment_method", paymentType)
      if (paymentType === 'PBMC') {
        if (WalletAddress !== "NA") {
          (async function () {
            setPBMCLoading(true)
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x1' }],
              });
            } catch (error) {
              console.error('Failed to switch network:', error);
            }

            const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
            try {
              const web3 = new Web3(window.ethereum);
              const token = new web3.eth.Contract(PBMC_ABI, contractAddress);
              const userAccount = WalletAddress
              const pbmValue = toWei("20").toString()
              const tx = await token.methods.transfer(process.env.REACT_APP_OWNER_ADDRESS, pbmValue).send({ from: userAccount })
              formData.append("transactionHash", tx.transactionHash)
              reset({ ...defaultValues })
              setFilePreview(null)
              api(formData)

            } catch (err) {
              setPBMCLoading(false)
              console.log(err);
            }
          })()
        } else {
          dispatch(updatePBMCValue(!togglePBMCAmount))
        }
      }


      function api() {
        if (paymentType === 'fiat') {
          setLoading(true)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/kyc/createInvoice`, formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then((res) => {
          setAllInvoice(res.data)
          if (res?.data) {

            setTimeout(() => {
              if (paymentType === 'fiat') {
                setCardShow(true)
                setLoading(false)
              }
              setShow(false)
              setPBMCLoading(false)
            }, 500)
            if (res.data.details.payment_method === "PBMC") {
              navigate("/dashboard/invoice_overview")
            }
          }
        })
          .catch((err) => {
            console.log(" invoice submission detail  err", err)
            setPBMCLoading(false)
          })
      }
    }

  }
  // ================================== HIDE POPUP ==================================
  const handleEditClose = () =>{
    setShowBankDetail(false)
  } 
  //====================== update invoice submission ================
  function updateapi(formData) {
    if (paymentType === 'fiat') {
      setLoading(true)
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/v1/kyc/updateInvoice`, formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((res) => {
      setAllInvoice(res.data)
      if (res?.data) {

        setTimeout(() => {
          if (res.data.details.payment_method === 'fiat') {
            setShowBankDetail(false)
            setLoading(false)
            navigate("/dashboard/invoice_overview")
          }
          setShow(false)
          setPBMCLoading(false)
        }, 500)
        if (res.data.details.payment_method === "PBMC") {
          navigate("/dashboard/invoice_overview")
        }
      }
    })
      .catch((err) => {
        console.log("update  submission detail  err", err)
        setTransactionLoading(false)
      })
  }
  //====================== api invoice submission ================
  function api(formData) {
    if (paymentType === 'fiat') {
      setLoading(true)
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/v1/kyc/createInvoice`, formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((res) => {
      setAllInvoice(res.data)
      if (res?.data) {

        setTimeout(() => {
          if (res.data.details.payment_method === 'fiat') {
            setShowBankDetail(false)
            setLoading(false)
            navigate("/dashboard/invoice_overview")
          }

          setPBMCLoading(false)
        }, 500)
        if (res.data.details.payment_method === "PBMC") {
          navigate("/dashboard/invoice_overview")
        }
      }
    })
      .catch((err) => {
        console.log(" invoice submission detail  err", err)
        setTransactionLoading(false)
      })
  }
  //=============== send transsaction id api ================
  const onSubmit = (data) => {
    setTransactionLoading(true)


    if (slug) {
      let formData = new FormData()
      formData.append("invoiceCompany", invoiceData.company_name)
      formData.append("invoiceAmount", Number(invoiceData.invoice_amount))
      formData.append("invoiceNumber", invoiceData.invoice_number)
      formData.append("invoiceLocation", invoiceData.location)
      formData.append("invoiceDate", invoiceData.date)
      if (invoiceData.attachment_file) {
        formData.append("document_image", invoiceData.attachment_file[0])
      }

      formData.append("authId", loginData._id)
      if (!buyerId) {
        formData.append("buyerId", editBuyerData.buyerId)
        formData.append("invoiceBuyer_Name", editBuyerData.buyerName)
      } else {
        formData.append("buyerId", buyerId._id)
        formData.append("invoiceBuyer_Name", buyerId.invoiceBuyer_Name)
      }
      formData.append("invoiceBuyer_Contact", invoiceData.buyer_contact)
      formData.append("invoiceBuyer_RegistrationNo", invoiceData.id_no)
      formData.append("invoiceBuyer_Address", invoiceData.buyer_address)
      formData.append("buyer_email", invoiceData.buyer_email)
      formData.append("company_email", invoiceData.company_email)
      formData.append("walletAddress", WalletAddress)
      formData.append("iso_code", invoiceData.iso_code)
      formData.append("payment_method", paymentType)
      formData.append("id", slug)

      // const saveData = invoiceCharges.find((ele) => ele.iso_code === invoiceData.iso_code)
      formData.append("charge_Amount", chargeAmount.amount)
      formData.append("invoice_Currency", chargeAmount.currency)

      formData.append("bank_Name", bankName)
      formData.append("beneficiary", beneficiary)
      formData.append("IBAN", IBAN)
      formData.append("bank_swift", swift)
      formData.append("transaction_id", data.transaction_id)



      updateapi(formData)
    } else {
      let formData = new FormData()
      formData.append("invoiceCompany", invoiceData.company_name)
      formData.append("invoiceAmount", Number(invoiceData.invoice_amount))
      formData.append("invoiceNumber", invoiceData.invoice_number)
      formData.append("invoiceLocation", invoiceData.location)
      formData.append("invoiceDate", invoiceData.date)
      formData.append("document_image", invoiceData.attachment_file[0])
      formData.append("authId", loginData._id)
      formData.append("buyerId", buyerId._id)
      formData.append("invoiceBuyer_Name", buyerId.invoiceBuyer_Name)
      formData.append("invoiceBuyer_Contact", invoiceData.buyer_contact)
      formData.append("invoiceBuyer_RegistrationNo", invoiceData.id_no)
      formData.append("invoiceBuyer_Address", invoiceData.buyer_address)
      formData.append("buyer_email", invoiceData.buyer_email)
      formData.append("company_email", invoiceData.company_email)
      formData.append("walletAddress", WalletAddress)
      formData.append("iso_code", invoiceData.iso_code)
      formData.append("payment_method", paymentType)

      const saveData = invoiceCharges.find((ele) => ele.iso_code === invoiceData.iso_code)
      formData.append("charge_Amount", saveData.invoiceChargeAmount)
      formData.append("invoice_Currency", saveData.invoiceChargeCurrency)

      formData.append("bank_Name", bankName)
      formData.append("beneficiary", beneficiary)
      formData.append("IBAN", IBAN)
      formData.append("bank_swift", swift)
      formData.append("transaction_id", data.transaction_id)

      api(formData)
    }

  }

  function toWei(amount){
    return amount * 10 **14
  }

  return (
    <>
      <Modal show={show} centered className='proceed_modal'>
        <Modal.Body>
          <div className="close_btn" onClick={handleClose}><MdClose /></div>
          <div className="proceed_btns">
            {loading ?
              <button className='primary_btn'>
                <div className="spinner-border spinner-border-sm text-light" role="status"></div>
              </button>
              :
              <button className='primary_btn' onClick={() => handleFiat('fiat')} disabled={PBMCloading}>
                Pay By Fiat Currency
              </button>
            }

            {PBMCloading ?
              <button className='primary_btn mt-4'>
                <div className="spinner-border spinner-border-sm text-light" role="status"></div>
              </button>
              :
              <button className='primary_btn mt-4' onClick={() => handleFiat('PBMC')} disabled={loading}>
                Pay By PBMC
              </button>
            }
          </div>
        </Modal.Body>

      </Modal>


      <Modal show={showBankDetail} onHide={handleEditClose}centered className='proceed_modal'>
      <Modal.Header closeButton>
                    <Modal.Title>Invoice Submission</Modal.Title>
                </Modal.Header>
        <Modal.Body>
          <div className="bankDetails_form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="warning_msg">
                <BsFillExclamationCircleFill />
                <p>To ensure that your invoice is accepted, you must pay either $75 or €75 as a submission fee.</p>
              </div>

              <Table>
                <tbody>
                  <tr>
                    <th>Bank Name</th>
                    <td>{bankName}</td>
                  </tr>
                  <tr>
                    <th>Bank (SWIFT / BIC)</th>
                    <td>{swift}</td>
                  </tr>
                  <tr>
                    <th>Beneficiary</th>
                    <td>{beneficiary}</td>
                  </tr>
                  <tr>
                    <th>Currency</th>
                    <td>{currency}</td>
                  </tr>
                  <tr>
                    <th>Account number</th>
                    <td>{accountNum}</td>
                  </tr>
                  <tr>
                    <th>Investec USD Correspondent</th>
                    <td>{correspondent}</td>
                  </tr>
                  <tr>
                    <th>Charge Amount</th>
                    <td>
                      {chargeAmount.symbol} {chargeAmount.amount}
                    </td>
                  </tr>
                  <tr>
                    <th>Transaction ID</th>
                    <td>
                      <input type="text" className='form-control' placeholder='Enter transaction id of payment' disabled = {transactionLoading}
                        {...register("transaction_id", {
                          required: "Transaction ID is required",
                        })}
                      />
                      {errors.transaction_id && <small className='error_msg_class ps-0'>{errors.transaction_id.message}</small>}
                    </td>
                  </tr>
                </tbody>
              </Table>


              <div className="send_btn d-flex justify-content-end">
                {transactionLoading ?
                  <button className='btn btn-success px-4'>
                    <div className="spinner-border spinner-border-sm text-light" role="status"></div>
                  </button>
                  :
                  <button className='btn btn-success' type='submit'>Send</button>
                }
              </div>
            </form>
          </div>

        </Modal.Body>

      </Modal>
    </>
  )
}

export default Proceed_modal