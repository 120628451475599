import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { FiSearch } from 'react-icons/fi'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useGetCurrencyBySymbolQuery } from '../../../redux/CurrencyAPI';
import { useGetPBMCBySymbolQuery } from '../../../redux/PBMC_currencyAPI';

// ================================================ Limit Future Date Start ==========================================================
const correctFormat = (number) => number < 10 ? '0' + number : number
const currentDate = `${new Date().getFullYear()}-${correctFormat(new Date().getMonth() + 1)}-${correctFormat(new Date().getDate())}`
// ================================================ Limit Future Date end ==========================================================

function Invoice_funded() {
  const { data: ETH_TO_USD, isLoading: ETH_TO_USD_load } = useGetCurrencyBySymbolQuery('ETHUSDT')
  const { data: EUR_TO_USD, isLoading: EUR_TO_USD_load } = useGetCurrencyBySymbolQuery('EURUSDT')
  const { data: PBMC_currency } = useGetPBMCBySymbolQuery()

  let PBMCAdminVal = PBMC_currency && PBMC_currency.PBMCoin[0].coin_price
  const accessToken = sessionStorage.getItem('accessToken')
  

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);

    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);

    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);

    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    };


    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [investorFundedData, setInvestorFundedData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [loader, setLoader] = useState(true)
  const [InvoiceDataType, setInvoiceDataType] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - investorFundedData.length) : 0;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };
  // ============================================
  const getInvestorFundedDetails = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/allInvoiceFunded`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        setLoader(false)
        setInvestorFundedData(res.data.invoiceFunded)
      })
      .catch((err) => {
        setLoader(false)
        console.log("investor Funded err---", err)
      })
  }
  useEffect(() => {
    getInvestorFundedDetails()
  }, [])
 
  // ****** search filter start******
  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
    setInvoiceDataType("search")
  }

  function getSearchList() {
    if (!searchTerm) {
      return investorFundedData;
    }
    return investorFundedData.filter((val) => val.invoiceNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
      val.invoiceBuyer_Name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
      val.supplierName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    )
  }
  let SearchList = useMemo(getSearchList, [searchTerm, investorFundedData]);

  //  filter when select status=====
  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value)
    setInvoiceDataType("filter")
  }

  function getFilteredList() {
    if (!statusFilter || statusFilter === "all") {
      return investorFundedData;
    }
    return investorFundedData.filter((item) => (item.fundedDate && item.fundedDate.slice(0, 10) === statusFilter)
      
    );
  }
  let filteredList = useMemo(getFilteredList, [statusFilter, investorFundedData]);

  // ****** search filter end******
  function dateConvert(userDate) {
    let today = new Date(userDate);
    let dueDat = new Date(new Date().setDate(today.getDate() + 15));
    let date = JSON.stringify(dueDat)
  
    return date.slice(1, 11)
}

// =======================================

function CurrencyType(symbol, value) {
  let ETH;
  let USD_;
  let EUR;

  if (ETH_TO_USD_load === false) {
      const USD = PBMCAdminVal
      const PBMC = (1 / USD).toFixed(2)
      const ethInPbmc = Number(USD / ETH_TO_USD.price).toFixed(4)
      if (EUR_TO_USD_load === false) {
          const eur = Number(1 / EUR_TO_USD.price).toFixed(2)
          const eurInPbmc = (eur * 5).toFixed(2)

          ETH = Number(ethInPbmc);
          USD_ = Number(PBMC);
          EUR = Number(eurInPbmc);

      }
  }
  switch (symbol) {
      case 'ETH':
          return (Number(value) / ETH).toFixed(2)
      case 'USD':
          return (Number(value) * USD_).toFixed(2)
      case 'EUR':
          return (Number(value) / EUR).toFixed(2)
  }
}

// =======================================


  return (
    <>
      <div className="invoice_overview_section">
        <Container fluid className='px-0'>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="invoice_overview_content">
                <div className="invoice_title">
                  <h6>Invoice Funded</h6>
                </div>
                <div className="table_content">
                  <div className="table_search">

                    <div className="search_input">
                      <div className="search_icon"><FiSearch /></div>
                      <input type="search" placeholder='search' className='form-control' value={searchTerm} onChange={(e) => handleSearch(e)} />
                    </div>

                    <div className="filter_input">
                      <label htmlFor="">Filter by Date</label>
                      <input type="date" placeholder="DD-MM-YYYY" max={currentDate} className='form-control' onChange={(e)=>handleStatusFilter(e)} />
                    </div>

                  </div>

                  <div className="invoice_table">
                    <TableContainer  >
                      <Table aria-label="custom pagination table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Buyer</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Amount Funded</TableCell>
                            {/* <TableCell>Amount (in PBMC)</TableCell> */}
                            <TableCell>Payment Due Date</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            loader ?
                              <TableRow>
                                <TableCell colSpan={12} >
                                  <div className='text-center' style={{ height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Spinner animation="border" variant="danger" />
                                  </div>
                                </TableCell>
                              </TableRow>
                              :
                              filteredList.length > 0 || SearchList.length > 0 ?
                                (InvoiceDataType === "search" ?
                                  (rowsPerPage > 0
                                    ? SearchList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : SearchList
                                  ) :
                                  (rowsPerPage > 0
                                    ? filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredList
                                  )
                                ).map((e, i) => {


                                  return (
                                    <TableRow key={i}>
                                      <TableCell>{e.invoiceNumber}</TableCell>
                                      <TableCell>{e.invoiceBuyer_Name}</TableCell>
                                      <TableCell>{e.supplierName}</TableCell>
                                      <TableCell>{e.fundedDate ? e.fundedDate.split("T").shift() : "-"} </TableCell>
                                      <TableCell>{e.invoiceAmount} {e.iso_codeInvoice}</TableCell>
                                      {/* <TableCell>{CurrencyType(e.iso_codeInvoice, e.invoiceAmount)} PBMC</TableCell> */}
                                      <TableCell>
                                        {
                                          e.fundedDate ? dateConvert(e.fundedDate) : "-"
                                        }
                                      </TableCell>
                                      <TableCell><span className='new_invoice'>{e.investor_status === true ? "Funded" : ""}</span></TableCell>
                                    </TableRow>
                                  )
                                })
                                :
                                <TableRow>
                                  <TableCell colSpan={12} ><h4 className='text-center' style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>No data found</h4></TableCell>
                                </TableRow>
                          }
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={4} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                              colSpan={11}
                              count={InvoiceDataType === "search" ? SearchList.length : filteredList.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  'aria-label': 'rows per page',
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Invoice_funded