import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import Cookies from 'js-cookie'
import Web3 from 'web3';
import { updatePBMCValue } from "../../redux/reducer";
import { useSelector, useDispatch } from 'react-redux';
import { usdAbi } from "../../contract/ERC20_ABI1"
function MyLoan() {
  const dispatch = useDispatch()
  const WalletAddress = useSelector(state => state.walletBalance.walletAddress)
  const OpenMetamask = useSelector(state => state.user.updatePBMCAmount)
  const [UpdateData, setUpdateData] = useState(false)
  const [Loading, setLoading] = useState(NaN)
  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);

    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);

    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);

    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    };


    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allLendingData, setAllLendingData] = useState([])
  const [laoder, setLoader] = useState(true)
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allLendingData.length) : 0;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  //========== get invoice details user start ===============//
  const [loginData, setLoginData] = useState()
  const loginUserData = sessionStorage.getItem('loggedInUserData')
  useEffect(() => {
    if (loginUserData) {
      setLoginData(JSON.parse(loginUserData)._id)
    }
  }, [loginUserData])

  //========== get lending details user start ===============//
  const accessToken = sessionStorage.getItem('accessToken')
  const getAllLendingData = async () => {
    try {
      if (loginUserData) {
        const apiData = await axios.get(`${process.env.REACT_APP_BASE_URL}/funds/getloandetails/${loginData}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          })
        setLoader(false)
        setAllLendingData(apiData.data.details.reverse())
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false)
    }

  }

  useEffect(() => {
    if (loginData) {
      getAllLendingData()
    }
  }, [UpdateData, loginData])







  //========== get lending details user start ===============//
  const web3 = new Web3(window.ethereum);
  const PBMC_Address = process.env.REACT_APP_CONTRACT_ADDRESS;
  const admin_Address = process.env.REACT_APP_OWNER_ADDRESS;
  const token = new web3.eth.Contract(usdAbi, PBMC_Address);
  async function sendPBMCToken(event, createdAt, Interest) {
    if (WalletAddress !== "NA") {
      try {
        console.log("Interest", Interest);
        const _id = event.target.getAttribute('data-id')
        const index = event.target.getAttribute('data-value')
        setLoading(index)
        const amount = document.getElementsByClassName('pbmcAmount')[index].getAttribute('amount')
        const convertAmount = Number(amount)
        const concatAmountAndInterest = convertAmount + Number(Interest)
        const convertWei = (concatAmountAndInterest * 10 ** 14)
        const removeDecimal = Math.trunc(convertWei);
        const tx = await token.methods.transfer(admin_Address, removeDecimal.toString()).send({ from: WalletAddress })
        console.log("tx", tx);
        if (tx.transactionHash.length > 0) {
          api(_id, tx.transactionHash)
        }
        // console.log(removeDecimal, typeof removeDecimal,  convertAmount, typeof convertAmount, concatAmountAndInterest, typeof concatAmountAndInterest,convertWei ,typeof convertWei);
        // const LoanAmount = Number(amount);
        // console.log([LoanAmount, typeof LoanAmount, LoanAmount, typeof LoanAmount]);
        // const CreatedDate = createdAt
        // const perDayPercentage = 0.033 //based on 1 percent per month
        // const CreatedDateTimeStamp = Date.parse(CreatedDate)
        // const CurrentDateTimeStamp = Date.now()
        // const date1 = new Date(CreatedDateTimeStamp);
        // const date2 = new Date(CurrentDateTimeStamp);
        // const timeDifference = date2.getTime() - date1.getTime();
        // const millisecondsInADay = 1000 * 60 * 60 * 24;
        // const daysDifference = Math.floor(timeDifference / millisecondsInADay) + 1;
        // const ReturnLoanPercentage = daysDifference * perDayPercentage
        // const LoanInterestAmount = LoanAmount * ReturnLoanPercentage / 100 //get loan amount percentage
        // const LoanAmountWithInterest = LoanAmount + LoanInterestAmount
        // const pbmValue = (LoanAmountWithInterest * 10 ** 14);
        // const removeDecimal = Math.trunc(pbmValue)
        // const tx = await token.methods.transfer(admin_Address, removeDecimal.toString()).send({ from: WalletAddress })
        // console.log("tx", tx);
        // if (tx.transactionHash.length > 0) {
        //   api(_id, tx.transactionHash)
        // }
        // console.log(pbmValue, typeof pbmValue, LoanInterestAmount, typeof LoanInterestAmount, LoanAmount, typeof LoanAmount, LoanAmountWithInterest, typeof LoanAmountWithInterest);
        // const ReceivedLoanDays = Math.floor((CurrentDateTimeStamp - CreatedDateTimeStamp) / 86400000) + 1 //1Day in millisecond
        // const ReturnLoanPercentage = ReceivedLoanDays * perDayPercentage
        // console.log("ReturnLoanPercentage", ReturnLoanPercentage, typeof ReturnLoanPercentage, "ReceivedLoanDays",ReceivedLoanDays,  typeof ReceivedLoanDays, CurrentDateTimeStamp, CreatedDateTimeStamp);
        // const LoanInterestAmount = LoanAmount * ReturnLoanPercentage / 100 //get loan amount percentage
        // const LoanAmountWithInterest = LoanAmount + LoanInterestAmount
        // debugger;
        // console.log({ CreatedDate, LoanAmount, amount, pbmValue, LoanAmountWithInterest, LoanInterestAmount, ReturnLoanPercentage, ReceivedLoanDays, CurrentDateTimeStamp, CreatedDateTimeStamp, perDayPercentage});
        // const pbmValue = (LoanAmountWithInterest * 10 ** 14);
        // const tx = await token.methods.transfer(admin_Address, pbmValue.toString()).send({ from: WalletAddress })
        // console.log("tx", tx);
        // if (tx.transactionHash.length > 0) {
        //   api(_id, tx.transactionHash)
        // }
        // console.log({ pbmValue, LoanAmount, amount, CreatedDate, perDayPercentage, CreatedDateTimeStamp, CurrentDateTimeStamp, ReceivedLoanDays, ReturnLoanPercentage, LoanInterestAmount, LoanAmountWithInterest });
        // if (ReceivedLoanDays == 0) {
        //   const convertAmount  = Number(amount); 
        //   const addAmount = convertAmount + 0.033amount
        //   const amountToWei = (addAmount * 10 ** 14).toString()
        //   console.log(amountToWei, typeof amountToWei);
        //   const tx = await token.methods.transfer(admin_Address, amountToWei).send({ from: WalletAddress })
        //   console.log("tx", tx);
        //   if (tx.transactionHash.length > 0) {
        //     api(_id, tx.transactionHash)
        //   }
        // } else {
        //   const tx = await token.methods.transfer(admin_Address, pbmValue.toString()).send({ from: WalletAddress })
        //   console.log("tx", tx);
        //   if (tx.transactionHash.length > 0) {
        //     api(_id, tx.transactionHash)
        //   }
        // }
      } catch (err) {
        console.log(err);
        setLoading(NaN)
      }
    } else {
      dispatch(updatePBMCValue(!OpenMetamask))
    }
  }
  // =================================================== Token Transfer ===========================================================

  function convertDayInterest(evenData) {
    const CreatedDate = evenData.createdAt
    const perDayPercentage = evenData.pbmc_percentage / 30 //based on 1 percent per month
    const returnLoanDate = evenData.loanUpdateDate;
    if(returnLoanDate == null){
      const CreatedDateTimeStamp = Date.parse(CreatedDate)
      const CurrentDateTimeStamp = Date.now()
      const date1 = new Date(CreatedDateTimeStamp);
      const date2 = new Date(CurrentDateTimeStamp);
      const timeDifference = date2.getTime() - date1.getTime();
      const millisecondsInADay = 1000 * 60 * 60 * 24;
      const daysDifference = Math.floor(timeDifference / millisecondsInADay) + 1;
      const daysDifferenceConvert = daysDifference * perDayPercentage
      const finalValue = daysDifferenceConvert //based on 1 percent per month 
      return finalValue.toFixed(4)
    } else{
      const CreatedDateTimeStamp = Date.parse(CreatedDate)
      const CurrentDateTimeStamp = Date.parse(returnLoanDate)
      const date1 = new Date(CreatedDateTimeStamp);
      const date2 = new Date(CurrentDateTimeStamp);
      const timeDifference = date2.getTime() - date1.getTime();
      const millisecondsInADay = 1000 * 60 * 60 * 24;
      const daysDifference = Math.floor(timeDifference / millisecondsInADay) + 1;
      const daysDifferenceConvert = daysDifference * perDayPercentage
      const finalValue = daysDifferenceConvert //based on 1 percent per month 
      return finalValue.toFixed(4)
    }
  }
  function api($id, $txnHash) {
    const body = {
      "transaction_hash": $txnHash,
      "id": $id,
    }
    console.log(body, accessToken);
    axios.post(`${process.env.REACT_APP_BASE_URL}/funds/returnLoanSupplier`, body,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      }).then(e => {
        setUpdateData(!UpdateData)
        setLoading(NaN)
      }
      ).catch(e => {
        console.log(e)
        setLoading(NaN)
      }
      )
  }
  return (
    <>
      <div className="invoice_overview_section">
        <Container fluid className='px-0'>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="invoice_overview_content">
                <div className="invoice_title">
                  <h6>Lending Request List</h6>
                </div>
                <div className="table_content">
                  <div className="invoice_table">
                    <TableContainer  >
                      <Table aria-label="custom pagination table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Transaction Id</TableCell>
                            <TableCell>Borrower's wallet address</TableCell>
                            <TableCell>Collateral Amount</TableCell>
                            <TableCell>Collateral Currency</TableCell>
                            <TableCell>PBMC Borrowed</TableCell>
                            <TableCell>Loan Term</TableCell>
                            <TableCell>APR</TableCell>
                            <TableCell>Yearly Interest</TableCell>
                            <TableCell>Daily Interest</TableCell>
                            <TableCell>Lending Start Time</TableCell>
                            <TableCell>Lending End Time</TableCell>
                            <TableCell>Repay Loan</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            laoder ?
                              <TableRow>
                                <TableCell colSpan={13} >
                                  <div className='text-center' style={{ height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Spinner animation="border" variant="danger" />
                                  </div>
                                </TableCell>
                              </TableRow>
                              :
                              allLendingData.length > 0 ?
                                (rowsPerPage > 0
                                  ? allLendingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  : allLendingData
                                ).map((e, i) => {
                                  return (
                                    <TableRow key={i}>
                                      <TableCell>{e.transactionId}</TableCell>
                                      <TableCell className='walletAddress' address={e.person_address}>{e.person_address}</TableCell>
                                      <TableCell>{String(e.received_currency).charAt(1) == "e" ? Number(e.received_currency).toFixed(9).replace(/\.?0+$/,"") : e.received_currency}</TableCell>
                                      <TableCell><div style={{ textTransform: "uppercase" }}>{e.payment_type}</div></TableCell>
                                      <TableCell className='pbmcAmount' amount={e.pbmc_amount}>{e.pbmc_amount ? e.pbmc_amount.toFixed(3) : "-"}</TableCell>
                                      <TableCell>{e.loanTerm}</TableCell>
                                      <TableCell>{e.apr_rate}</TableCell>
                                      <TableCell className='interestAmount' interest={e.pbmc_percentage}>{e.pbmc_percentage ? e.pbmc_percentage + " PBMC" : "-"} </TableCell>
                                      <TableCell>{convertDayInterest(e)}</TableCell>
                                      <TableCell>{e.createdAt?.slice(0, 10)}</TableCell>
                                      <TableCell>{e.return_status === "success" ? e.updatedAt?.slice(0, 10) : "N/A"}</TableCell>
                                      <TableCell>
                                        {
                                          e.return_status === "success" ?
                                            <a target='_blank' href={`https://etherscan.io/tx/${e.reciveAdminHash}`}>
                                              {`${e.reciveAdminHash?.slice(0, 4)}...${e.reciveAdminHash?.slice(-4)}`}</a> :
                                            e.recive_status == "pending" ?
                                              "Waiting" :
                                              i == Loading ?
                                                <button className='btn btn-primary'><Spinner variant='light' size='sm' /></button> :
                                                <button data-id={e._id} data-value={i} className='btn btn-primary' onClick={(event) => sendPBMCToken(event, e.createdAt, convertDayInterest(e))} style={{ padding: "2px 10px" }}>Send</button>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                                :
                                <TableRow>
                                  <TableCell colSpan={13} ><h4 className='text-center' style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>No data found</h4></TableCell>
                                </TableRow>
                          }
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={13} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                              colSpan={13}
                              count={allLendingData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  'aria-label': 'rows per page',
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MyLoan