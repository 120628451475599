import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    walletAddress: "NA",
    tokenBalance: "0"
};
export const walletBalances = createSlice({
    name: 'walletBalance',
    initialState,
    reducers: {
        getWalletAddress: (state, action) => {
            state.walletAddress = action.payload
        },
        getTokenBalance(state, action) {
            state.tokenBalance = action.payload
        }
    },
})


export const { getWalletAddress, getTokenBalance } = walletBalances.actions;

export default walletBalances.reducer