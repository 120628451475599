import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";

function VerifyOTP() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const navigate = useNavigate()
    const login_type = sessionStorage.getItem('login_type')
    const [loginData, setLoginData] = useState(null)
    const accessToken = sessionStorage.getItem('accessToken')
    const loginUserData = sessionStorage.getItem('loggedInUserData')
    const [otp, setOtp] = useState("")
    // const [otpErr, setOtpErr] = useState("")
    const [EmailOtp, setEmailOtp] = useState('')
    const [PhoneOtp, setPhoneOtp] = useState('')
    const [loader, setLoader] = useState(false);
    const [showMsg, setShowMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")
    const [counter, setCounter] = useState(30)
    const [BothInvalidOtp, setBothInvalidOtp] = useState("")
    const [EmailDisabled, setEmailDisabled] = useState(true)
    useEffect(() => {
        if (loginUserData) {
            setLoginData(JSON.parse(loginUserData))
        }
    }, [])

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
        return () => clearInterval(timer)
    }, [counter])


    const handleResetOTP = (e, type) => {
        setLoader(true)
        let resetOtpData = { "id": loginData._id, "type": type }
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/resetOtp`, resetOtpData)
            .then((res) => {
                setTimeout(() => {
                    setLoader(false)
                    setSuccessMsg("OTP resent successfully")
                }, 1000)
                setTimeout(() => {
                    setShowMsg(true)
                }, 1200)

            })
            .catch((err) => {
                console.log("reset otp err----", err)
                setLoader(false)
            })
        e.preventDefault()
    }
    // =========================================== Verify OTP ========================================
    const onSubmit = (data, e, o) => {
        console.log(o);
        setLoader(true)
        const otpData = { "id": loginData._id, "otp": Number(data.email_otp), "phoneOTP": data.mobile_otp }

        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/verifyOtp`, otpData)
            .then((res) => {
                // sessionStorage.setItem('accessToken', res.data.token)
                setTimeout(() => {
                    setLoader(false)
                    setSuccessMsg("OTP verified successfully")
                }, 1000)

                setTimeout(() => {
                    setShowMsg(true)
                }, 1200)
                setTimeout(() => {
                    setOtp("")
                    navigate('/authenticator')
                }, 3000)

            })
            // .catch(({ response: { data: { type, message } } }) => {
            .catch(({ response: { data: { message, type } } }) => {
                setLoader(false)
                if (type !== undefined) {
                    if (type === "Phone") {
                        setPhoneOtp(message)
                        timeOut(setPhoneOtp, "", 5000)
                    } else if (type === "Email") {
                        setEmailOtp(message)
                        timeOut(setEmailOtp, "", 5000)
                    }
                } else {
                    setBothInvalidOtp(message)
                    timeOut(setBothInvalidOtp, "", 5000)
                }
            })

    }
    function timeOut(state, data, time) {
        setTimeout(() => {
            state(data)
        }, time);
    }
    // ============================================== Disabled BTN =====================================================
    useEffect(() => {
        timeOut(setEmailDisabled, false, 30000)
    }, [])
    return (
        <>
            {
                loader ? <div className="loader_wrap"><div className="lds-hourglass"></div></div> : <div></div>
            }
            <div className="login_section">
                <div className='text_bg'>
                    <div className='text'>DEFI <br></br> FINANCE</div>
                    <div className='text'>SECURE <br></br> TRUSTED  </div>
                </div>
                <Container>
                    <Row className=''>
                        <Col xl={5} lg={7} md={8} sm={12}>
                            <div className='login_wrapper'>
                                <div className='login_box'>
                                    {
                                        showMsg ?
                                            <div className="success_message">
                                                <p>{successMsg}</p>
                                            </div>
                                            :
                                            " "
                                    }
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h5 className='otp_title'>Please enter the One-Time Password to verify your account</h5>
                                        <p className='otp_subtitle'>A One-Time Password has been sent to <span>{loginData && loginData.email}</span> and <span>{loginData && loginData.phoneNumber}</span></p>
                                        <Form.Group className="mt-3 d-flex otp_field" controlId="formBasicLastName">
                                            <Form.Control type="number" placeholder="Enter Email OTP"
                                                {...register("email_otp", {
                                                    required: "Email OTP is required",
                                                    minLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    }
                                                })}
                                                onWheel={(e) => e.target.blur()} />
                                            {EmailDisabled ?
                                                <button className='primary_btn' disabled>Resend</button>
                                                :
                                                <button className='primary_btn' onClick={(e) => handleResetOTP(e, "email")}>Resend</button>
                                            }
                                        </Form.Group>
                                        {errors.email_otp && <small className='error_msg_class'>{errors.email_otp.message}</small>}
                                        {EmailOtp ? <small className='error_msg_class'>{EmailOtp}</small> : ""}
                                        <Form.Group className="my-3 d-flex otp_field" controlId="formBasicLastName">
                                            <Form.Control type="number" placeholder="Enter Mobile OTP"
                                                {...register("mobile_otp", {
                                                    required: "Mobile OTP is required",
                                                    minLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    }
                                                })}
                                                onWheel={(e) => e.target.blur()} />
                                            {EmailDisabled ?
                                                <button className='primary_btn' disabled>Resend</button>
                                                :
                                                <button className='primary_btn' onClick={(e) => handleResetOTP(e, "phone")}>Resend</button>
                                            }
                                        </Form.Group>
                                        {errors.mobile_otp && <small className='error_msg_class'>{errors.mobile_otp.message}</small>}
                                        {PhoneOtp ? <small className='error_msg_class'>{PhoneOtp}</small> : ""}
                                        {BothInvalidOtp ? <small className='error_msg_class' style={{ padding: "0px" }}>{BothInvalidOtp}</small> : ""}
                                        <div className="otp_btns mt-4">
                                            {/* <button type='submit' onClick={handleResetOTP} className='primary_btn' style={{ backgroundColor: "#143652" }}>Reset OTP</button> */}
                                            <button type='submit' className='primary_btn'>Verify OTP</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

        </>
    )
}

export default VerifyOTP




