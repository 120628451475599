import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Form, Spinner, Button } from 'react-bootstrap'
import { FiSearch } from 'react-icons/fi'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import axios from 'axios';
import { BsFiletypeCsv } from 'react-icons/bs'
import { CSVLink } from 'react-csv';
import { PBMC_ABI } from '../../../contract/ERC20_ABI';
import { MINT_ABI } from '../../../contract/Mint';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import { useGetCurrencyBySymbolQuery } from '../../../redux/CurrencyAPI';
import { useGetPBMCBySymbolQuery } from '../../../redux/PBMC_currencyAPI';

const web3 = new Web3(window.ethereum)
// ================================================ Limit Future Date Start ==========================================================
const correctFormat = (number) => number < 10 ? '0' + number : number
const currentDate = `${new Date().getFullYear()}-${correctFormat(new Date().getMonth() + 1)}-${correctFormat(new Date().getDate())}`
// ================================================ Limit Future Date end ==========================================================

function Investro_all_invoices() {
    const { data: ETH_TO_USD, isLoading: ETH_TO_USD_load } = useGetCurrencyBySymbolQuery('ETHUSDT')
    const { data: EUR_TO_USD, isLoading: EUR_TO_USD_load } = useGetCurrencyBySymbolQuery('EURUSDT')
    const { data: PBMC_currency } = useGetPBMCBySymbolQuery()

    let PBMCAdminVal = PBMC_currency && PBMC_currency.PBMCoin[0].coin_price


    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);

        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);

        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);

        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

        };


        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [allInvoiceData, setAllInvoiceData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [filterData, setFilterData] = useState([])
    const [statusFilter, setStatusFilter] = useState("")
    const [laoder, setLoader] = useState(true)
    const [fileData, setFileData] = useState([])

    const [UpdateData, setUpdateData] = useState(false)
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allInvoiceData.length) : 0;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    //========== get invoice details user start ===============//
    const accessToken = sessionStorage.getItem('accessToken')
    const getAllInvoiceData = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/allInvoiceList`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            setLoader(false)
            let data = res.data.details
            setAllInvoiceData(data)
            setFilterData(data)
            for (let i = 0; i < data.length; i++) {
                let obj = {}
                obj["Invoice Number"] = data[i].invoiceNumber
                obj["Buyer Name"] = data[i].invoiceBuyer_Name
                obj["Supplier Name"] = data[i].supplier_Name
                obj["Location"] = data[i].invoiceLocation
                obj["Created Date"] = data[i].createdAt.split("T")[0]
                obj["Invoice Amount"] = data[i].invoiceAmount
                obj["Payment Status"] = data[i].invoiceStatus
                obj["Invoice Status"] = data[i].deposit_Status ? data[i].deposit_Status : "-"
                setFileData((a) => [...a, obj])
            }
        })
            .catch((err) => {
                console.log("invoice list err", err)
                setLoader(false)
            })
    }



    useEffect(() => {
        getAllInvoiceData()
    }, [UpdateData])

    //========== get invoice details user start ===============//

    const [InvoiceDataType, setInvoiceDataType] = useState("")
    // ****** search filter start******
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
        setInvoiceDataType("search")
    }

    function getSearchList() {
        if (!searchTerm) {
            return allInvoiceData;
        }
        return allInvoiceData.filter((val) => val.invoiceNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
    }
    let SearchList = useMemo(getSearchList, [searchTerm, allInvoiceData]);

    //  filter when selected status=====
    const handleStatusFilter = (e) => {
        setStatusFilter(e.target.value)
        setInvoiceDataType("filter")
    }

    function getFilteredList() {
        if (!statusFilter || statusFilter === "all") {
            return allInvoiceData;
        }
        return allInvoiceData.filter((item) => item.invoiceStatus === statusFilter);
    }

    let filteredList = useMemo(getFilteredList, [statusFilter, allInvoiceData]);

    // ****** search filter end******
    // =======================================

    function CurrencyType(symbol, value) {
        let ETH;
        let USD_;
        let EUR;
        if (ETH_TO_USD_load === false) {
            const USD = PBMCAdminVal
            const PBMC = (1 / USD).toFixed(2)
            const ethInPbmc = Number(USD / ETH_TO_USD.price).toFixed(4)
            if (EUR_TO_USD_load === false) {
                const eur = Number(1 / EUR_TO_USD.price).toFixed(2)
                const eurInPbmc = (eur * 5).toFixed(2)

                ETH = Number(ethInPbmc);
                USD_ = Number(PBMC);
                EUR = Number(eurInPbmc);

            }
        }
        switch (symbol) {
            case 'ETH':
                return (Number(value) / ETH).toFixed(2)
            case 'USD':
                return (Number(value) * USD_).toFixed(2)
            case 'EUR':
                return (Number(value) / EUR).toFixed(2)
            case 'PBMC':
                return (value).toFixed(2)
        }
    }

    // =======================================

    // ================================================= BUY ============================================================
    const [buyLoader, setBuyLoader] = useState(NaN)
    const contractAddress = process.env.REACT_APP_MINT_CONTRACT_ADDRESS
    const PBMC_Contract = process.env.REACT_APP_CONTRACT_ADDRESS
    const pbmc = new web3.eth.Contract(PBMC_ABI, PBMC_Contract); //for approve
    const token = new web3.eth.Contract(MINT_ABI, contractAddress); //for buy
    const WalletAddress = useSelector(state => state.walletBalance.walletAddress)
    async function Buy({ target }) {
        const index = target.getAttribute('data-id')
        setBuyLoader(index)
        const ID = target.getAttribute('user')
        const amount = target.getAttribute('allowance');
        const finalAmount = toWei(amount)
        const convertToFloor = Math.floor(finalAmount)
        const token_id = target.id;
        const Allowance = await pbmc.methods.allowance(WalletAddress, contractAddress).call()
        if (Allowance < convertToFloor) {
            try {
                await pbmc.methods.approve(contractAddress, convertToFloor.toString()).send({ from: WalletAddress }).then(async a => {
                    await token.methods.investorDeposit(token_id).send({ from: WalletAddress }).then(e => {
                        setBuyLoader(NaN)
                        const BuyData = {
                            "id": ID,
                            "action_status": true,
                            "investor_by_transactionhash": e.transactionHash
                        }
                        axios.put(`${process.env.REACT_APP_BASE_URL}/v1/kyc/collateral-buy`, BuyData, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }).then(data => {
                            console.log(data);
                            setUpdateData(!UpdateData)
                        }).catch(error => {
                            console.log(error);
                        })
                    }).catch(error => {
                        console.log(error);
                        setBuyLoader(NaN)
                    })
                }).catch(error => {
                    console.log(error);
                    setBuyLoader(NaN)
                })
            } catch (err) {
                console.log(err);
                setBuyLoader(NaN)
            }
        } else {
            try {
                await token.methods.investorDeposit(token_id).send({ from: WalletAddress }).then(e => {
                    setBuyLoader(NaN)
                    const BuyData = {
                        "id": ID,
                        "action_status": true,
                        "investor_by_transactionhash": e.transactionHash
                    }
                    axios.put(`${process.env.REACT_APP_BASE_URL}/v1/kyc/collateral-buy`, BuyData, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }).then(data => {
                        console.log(data);
                        setUpdateData(!UpdateData)
                    }).catch(error => {
                        console.log(error);
                    })
                }).catch(error => {
                    console.log(error);
                    setBuyLoader(NaN)
                })
            } catch (error) {
                console.log(error);
                setBuyLoader(NaN)
            }
        }

    }


    function toWei(amount) {
        return amount * 10 ** 14
    }
    // ================================================= BUY ============================================================
    return (
        <>
            <div className="invoice_overview_section">
                <Container fluid className='px-0'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="invoice_overview_content">
                                <div className="invoice_title">
                                    <h6>Invoice Overview</h6>
                                </div>
                                <div className="table_content">
                                    <div className="table_search">
                                        <div className='d-flex justify-space-between'>
                                            <div className="search_input">
                                                <div className="search_icon"><FiSearch /></div>
                                                <input type="search" placeholder='search' className='form-control' value={searchTerm} onChange={(e) => handleSearch(e)} />
                                            </div>
                                            <button className='btn btn-secondary ms-3'><CSVLink data={fileData} filename={"invoice_list.csv"} className='csv_link'><BsFiletypeCsv /> CSV </CSVLink></button>
                                        </div>

                                        <div className="filter_input">
                                            <label htmlFor="">Filter</label>
                                            <Form.Select aria-label="Default select example" onChange={(e) => handleStatusFilter(e)}>
                                                <option value="all">Select Status</option>
                                                <option value="all">All</option>
                                                <option value="pending">Pending</option>
                                                <option value="failed">Failed</option>
                                                <option value="success">Success</option>
                                            </Form.Select>
                                        </div>

                                    </div>

                                    <div className="invoice_table">
                                        <TableContainer  >
                                            <Table aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Invoice Number</TableCell>
                                                        <TableCell>Buyer Name</TableCell>
                                                        <TableCell>Supplier Name</TableCell>
                                                        <TableCell>Location</TableCell>
                                                        <TableCell>Created Date</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Amount (in PBMC)</TableCell>
                                                        <TableCell>Payment Status</TableCell>
                                                        <TableCell>Invoice Status</TableCell>
                                                        <TableCell>Buy Invoice</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {

                                                        laoder ?
                                                            <TableRow>
                                                                <TableCell colSpan={12} >
                                                                    <div className='text-center' style={{ height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <Spinner animation="border" variant="danger" />
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                            :
                                                            filteredList.length > 0 || SearchList.length > 0 ?
                                                                (InvoiceDataType === "search" ?
                                                                    (rowsPerPage > 0
                                                                        ? SearchList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        : SearchList
                                                                    ) :
                                                                    (rowsPerPage > 0
                                                                        ? filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        : filteredList
                                                                    )
                                                                ).map((e, i) => {
                                                                    return (
                                                                        <TableRow key={i}>
                                                                            <TableCell>{e.invoiceNumber}</TableCell>
                                                                            <TableCell>{e.invoiceBuyer_Name}</TableCell>
                                                                            <TableCell>{e.user.length > 0 ? e.user[0].first_Name : ""} {e.user.length > 0 ? e.user[0].last_Name : ""}</TableCell>
                                                                            <TableCell>{e.invoiceLocation}</TableCell>
                                                                            <TableCell>{e.createdAt.split("T")[0]}</TableCell>
                                                                            <TableCell>{e.invoiceAmount} {e.iso_codeInvoice}</TableCell>
                                                                            <TableCell>{CurrencyType(e.iso_codeInvoice, e.invoiceAmount)} PBMC</TableCell>
                                                                            <TableCell><div className={e.invoiceStatus === "failed" ? "closed_invoice" : e.invoiceStatus === "success" ? "new_invoice" : "inProgress_invoice"} >{e.invoiceStatus}</div></TableCell>
                                                                            <TableCell><div className={e.status ? e.status === "rejected" ? "closed_invoice" : e.deposit_Status === "success" ? "new_invoice" : "inProgress_invoice" : ""} >{e.deposit_Status ? e.deposit_Status : "-"}</div></TableCell>
                                                                            <TableCell>
                                                                                {e.deposit_Status === "pending" ?
                                                                                    <Button className='btn btn-warning' disabled={true} style={{ padding: "2px 10px" }}>Buy</Button>
                                                                                    :
                                                                                    buyLoader == i ? <Button className='btn btn-warning' style={{ padding: "3px 16px 0px" }}>  <Spinner animation="border" role="status" style={{
                                                                                        width: "15px"
                                                                                        , height: "15px"
                                                                                    }}>
                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                    </Spinner></Button> :
                                                                                        e.investor_by_transactionhash ?
                                                                                            <a target='_blank' href={`https://etherscan.io/tx/${e.investor_by_transactionhash}`}>view on etherscan</a>
                                                                                            :
                                                                                            <Button user={e._id} allowance={CurrencyType(e.iso_codeInvoice, e.invoiceAmount)} id={e.tokenId} data-id={i} onClick={Buy} className='btn btn-warning' style={{ padding: "2px 10px" }}>Buy</Button>
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell><Link to={`/dashboard/invoice_details/${e._id}`}>view</Link></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                                :
                                                                <TableRow>
                                                                    <TableCell colSpan={12} ><h4 className='text-center' style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>No data found</h4></TableCell>
                                                                </TableRow>
                                                    }
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={12} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                            colSpan={12}
                                                            count={InvoiceDataType === "search" ? SearchList.length : filteredList.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: {
                                                                    'aria-label': 'rows per page',
                                                                },
                                                                native: true,
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    )
}

export default Investro_all_invoices


