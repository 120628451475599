import React, { lazy, Suspense } from 'react'
import HeroSection from './HeroSection'
// import UserFeatures from './UserFeatures'
// import Counter from './Counter'
// import DashboardFeature from './DashboardFeature'
// import AboutTrade from './AboutTrade'
// import Contact from './Contact'
// import Footer from './common/Footer'

const LazyUserFeatures = lazy(() => import('./UserFeatures'))
const LazyCounter = lazy(() => import('./Counter'))
const LazyDashboardFeature = lazy(() => import('./DashboardFeature'))
const LazyAboutTrade = lazy(() => import('./AboutTrade'))
const LazyContact = lazy(() => import('./Contact'))
const LazyFooter = lazy(() => import('./common/Footer'))
const renderLoader = () => <p>Loading</p>;
function Landing() {

  return (
    <div className='landing_sections'>
      <HeroSection />
      <Suspense fallback={renderLoader}>
        <LazyUserFeatures />
        <LazyCounter />
        <LazyDashboardFeature />
        <LazyAboutTrade />
        <LazyContact />
        <LazyFooter />
      </Suspense>
    </div>
  )
}

export default Landing



