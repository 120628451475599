import React, { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { STAKE_ABI } from '../../../contract/Stake'
import Web3 from 'web3'
import { useSelector } from 'react-redux';
function StakingOverview() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,
        // text: 'Free Gala Area Chart ',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const data = {
    labels,
    datasets: [
      {
        label: 'PBM',
        data: [0, 50, 100, 150, 200, 250, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };
  // ========================================= Get total PBMC Stake ===========================================
  const WalletAddress = useSelector(state => state.walletBalance.walletAddress)

  const web3 = new Web3(window.ethereum);

  const [TotalPBMCStake, setTotalPBMCStake] = useState("")
  const [TotalStaker, setTotalStaker] = useState("")
  const [DistributeRewards, setDistributeRewards] = useState("")
  const [op, setop] = useState([])
  const [FilterByYear, setFilterByYear] = useState([])
  const ABI = STAKE_ABI;
  const ContractAddress = process.env.REACT_APP_STAKE_CONTRACT_ADDRESS;
  const stake = new web3.eth.Contract(ABI, ContractAddress);
  // ======================================================== Total Staked ============================================================
  async function getStakingAmount() {
    if (WalletAddress !== null) {
      if (WalletAddress !== "NA") {
        // const stakes = await stake.methods.totalStakeAmount().call()
        // const Total_amount =  (stakes / 10 ** 14)
        // setTotalPBMCStake(Total_amount)
      }
    }
  }
  // ================================================================
  // const a = ["3", "4", "2", "1", "2", "3", "1", "3", "1", "2"]
  // console.log(a)
  // let b = []

  // const d = new Set(a)
  // // console.log(d)
  // const final = new Array(...d)
  // // console.log(final)

  // for (let x = 0; x < final.length; x++) {
  //   for (let y = 0; y < a.length; y++) {
  //     if (final[x] === a[y]) {
  //       b.push(final[x])
  //     }
  //   }
  // }

  // console.log(b)
  const [graphData, setGraphData] = useState([])
  // ================================================================
  async function AllStakeData(params) {
    if (WalletAddress !== null) {
      if (WalletAddress !== "NA") {
        // stake.methods.getUsersPlans().call({ from: WalletAddress }).then(e => {
        //   // console.log("======>", e);
        //   const filterList = [...e.userOneYearPlans, ...e.userSixMonthPlans, ...e.userThreeMonthPlans]
        //   console.log("filterList ====> ", filterList);
        //   const ll = filterList.map((a) => {
        //     const z = new Date(a.startTime * 1000).toDateString().split(" ").pop()
        //     const m = new Date(1688712588 * 1000).toDateString().split(" ")
        //     return { ...a, "year": z, "month": m[2] }
        //   })

        //   console.log("ll ====> ", ll);
        //   setGraphData(ll)
        // }).catch(e => {
        //   console.log(e);
        // })
      }
    }

  }

  useEffect(() => {
    getStakingAmount()
    AllStakeData()
  }, [])

  let obj = {}
  let arr = []
  graphData.forEach((ele) => {
    if (obj.hasOwnProperty(ele.month)) {
      let amount = Web3.utils.fromWei(ele.amount)
      obj[ele.month] = obj[ele.month] + Number(amount)
    } else {
      let amount = Web3.utils.fromWei(ele.amount)
      obj[ele.month] = Number(amount)
    }
  })
  for (let ele in obj) {
    arr.push({ month: ele, amount: obj[ele] })
  }
  // ========================================= Get total PBMC Stake ===========================================
  return (
    <>
      <div className="staking_overview_section">
        <div className="head">
          <h6>Staking / Generating Overview</h6>
          <div className="staking_dropdown">
            <div>
              <select className=''>
                <option value="">Staked</option>
                <option value="">UnStaked</option>
              </select>
            </div>
            <div>
              <select className=''>
                <option value="">3 Months</option>
                <option value="">6 Months</option>
                <option value="">12 Months</option>
              </select>
            </div>
          </div>
        </div>



        <div className="staking_overview_chart">
          <h2>{TotalPBMCStake} <span>/PBMC staked</span></h2>
          <div className='line_chart_div'>
            <Line options={options} data={data} />
          </div>
        </div>
      </div>
    </>
  )
}

export default StakingOverview











