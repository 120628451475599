import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Stake from './Stake'
import UnStake from './UnStake'
function StakingToken() {


    return (
        <>
            <div className="staking_token_section">
                <Tabs
                    defaultActiveKey="staking"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3 staking_tabs"
                >
                    <Tab eventKey="staking" title="Staking">
                        <Stake />
                    </Tab>
                    <Tab eventKey="unstaking" title="Unstaking">
                        <UnStake />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default StakingToken

