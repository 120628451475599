
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const PBMC_CurrencyAPI = createApi({
    reducerPath: 'PBMC_currency_api',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}/admin/` }),
    endpoints: (builder) => ({
        getPBMCBySymbol: builder.query({
            query: () => ({
                url:`getPBM_Coin`,
                method: 'POST'
              }),
        }),
    }),
})


export const { useGetPBMCBySymbolQuery } = PBMC_CurrencyAPI