import React, { useState, useEffect } from 'react'
import { Col, Container, Image, Row, Tabs, Tab } from 'react-bootstrap'
import { MINT_ABI } from '../../../contract/Mint'
import Web3 from 'web3'
import axios from 'axios'
export default function AdminMarketplace() {

    // ======================================== GET ALL NFT =========================================
    const [NFTData, setNFTData] = useState([])
    const [AllInvoiceData, setAllInvoiceData] = useState([])
    const [NFTImage, setNFTImage] = useState([])
    const mint_contract = process.env.REACT_APP_MINT_CONTRACT_ADDRESS

    const web3 = new Web3(window.ethereum)
    const Instance = new web3.eth.Contract(MINT_ABI, mint_contract)

    function getNFT() {
        Instance.methods.getAllNfts().call().then(async e => {
            setNFTData(e.nfts)
        }).catch(e => {
            console.log(e);
        })
    }

    useEffect(() => {
        getNFT()
    }, [])
    // ======================================== GET ALL NFT =========================================
    //========== get invoice details user start ===============//
    const accessToken = sessionStorage.getItem('accessToken')
    const getAllInvoiceData = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/allInvoiceList`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            setAllInvoiceData(res.data.details.reverse())
        }).catch((err) => {
            console.log("Inovice Data Marketplace =======>", err)
        })
    }

    useEffect(() => {
        getAllInvoiceData()
    }, [])

    useEffect(() => {
        if (AllInvoiceData) {
            const FilterWallet = AllInvoiceData.filter(wallet => wallet.investor_status)
            const test = AllInvoiceData.filter(data => data.burn_status == "success")
        }
    }, [AllInvoiceData])



    return (
        <>
            <section className='admin_marketplace'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Tabs
                                defaultActiveKey="Minted"
                                id="uncontrolled-tab-example"
                                className="mb-3 allNFT"
                            >
                                <Tab eventKey="Minted" title="Minted">
                                    <Row>
                                        {AllInvoiceData.filter(data => data.mint_status === "success" && data.investor_status === false && data.burn_status === "pending").length > 0
                                            ?
                                            AllInvoiceData.filter(data => data.mint_status === "success" && data.investor_status === false && data.burn_status === "pending").map(({ document_image, transaction_hash }, key) => {
                                                return <Col lg={3} md={4} sm={6} key={key}>
                                                    <a target='_blank' href={`https://etherscan.io/tx/${transaction_hash}`}>
                                                        <div className='nft_card'>
                                                            <Image src={document_image} alt="nft" fluid className='nft_img' loading="lazy"/>
                                                            <div className='content'>
                                                                <h6>NFT Name</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </Col>
                                            }) :
                                            <div className="nft_card no_nft_found">No NFT found</div>
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="transferred" title="Transferred">
                                    <Row>
                                        {AllInvoiceData.filter(data => data.investor_status === true && data.burn_status === "pending").length > 0 ?
                                            AllInvoiceData.filter(data => data.investor_status === true && data.burn_status === "pending").map(({ document_image, transaction_hash
                                            }, key) => {
                                                return <Col lg={3} md={4} sm={6} key={key}>
                                                    <a target='_blank' href={`https://etherscan.io/tx/${transaction_hash}`}>
                                                        <div className='nft_card'>
                                                            <Image src={document_image} alt="nft" fluid className='nft_img' loading="lazy"/>
                                                            <div className='content'>
                                                                <h6>NFT #{key + 1}</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </Col>
                                            }) :
                                            <div className="nft_card no_nft_found">No NFT found</div>
                                        }
                                    </Row>
                                </Tab>
                                <Tab eventKey="burnt" title="Burnt">
                                    <Row>
                                        {AllInvoiceData.filter(data => data.burn_status == "success").length > 0 ?
                                            AllInvoiceData.filter(data => data.burn_status == "success").map(({ document_image, transaction_hash }, key) => {
                                                return <Col lg={3} md={4} sm={6} key={key}>
                                                    <a target='_blank' href={`https://etherscan.io/tx/${transaction_hash}`}>
                                                        <div className='nft_card'>
                                                            <Image src={document_image} alt="nft" fluid className='nft_img' loading="lazy"/>
                                                            <div className='content'>
                                                                <h6>NFT #{key + 1}</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </Col>
                                            }) :
                                            <div className="nft_card no_nft_found">No NFT found</div>
                                        }
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
