import { configureStore } from "@reduxjs/toolkit";
import useradmin from "./redux/reducer";

import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { CurrencyAPI } from "./redux/CurrencyAPI";
import { InviceChargeAPI } from "./redux/InvoiceChargeAPI";
import walletBalances from "./redux/walletAmountSlice";
import { PBMC_CurrencyAPI } from "./redux/PBMC_currencyAPI";

const store = configureStore({
    reducer: {
        user: useradmin,
        walletBalance: walletBalances,
        [CurrencyAPI.reducerPath]: CurrencyAPI.reducer,
        [InviceChargeAPI.reducerPath]:InviceChargeAPI.reducer,
        [PBMC_CurrencyAPI.reducerPath]:PBMC_CurrencyAPI.reducer
    },

    middleware:(getDefaultMiddleware)=> getDefaultMiddleware().concat(CurrencyAPI.middleware,InviceChargeAPI.middleware, PBMC_CurrencyAPI.middleware),
});

setupListeners(store.dispatch)

export default store;

