import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Web3 from 'web3';
import { STAKE_ABI } from '../../../../contract/Stake';
import axios from "axios"
function AllStaking() {
    const web3 = new Web3(window.ethereum)

    const [AllStake, setAllStake] = useState([])
    const [loader, setLoader] = useState(false);
    const Address = process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
    const stake = new web3.eth.Contract(STAKE_ABI, Address)
    const [newAllStake, setNewAllStake] = useState([])
    async function getAllStakers(params) {
        // setLoader(true)
        // const stakeList = await stake.methods.getAllUsersInfo().call()
        // setLoader(false)
        // setAllStake(stakeList.usersDetails)
    }


    useEffect(() => {
        getAllStakers()
    }, [])

    // =====================================================

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);

        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);

        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);

        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

        };


        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newAllStake.length) : 0;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };
	
    const accessToken = sessionStorage.getItem('accessToken')
    const allStakeAPI = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/funds/getStaking`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
            setNewAllStake(data.stakingData)
            console.log("data", data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        allStakeAPI()
    }, [])
    return (
        <>
            <div className="invoice_overview_section">
                <Container fluid className='px-0'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="invoice_overview_content">
                                <div className="invoice_title">
                                    <h6>Staking List</h6>
                                </div>
                                <div className="table_content">
                                    <div className="invoice_table">
                                        <TableContainer  >
                                            <Table aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>User Wallet Address</TableCell>
                                                        <TableCell>PBMC Staked</TableCell>
                                                        <TableCell>From Date/Time</TableCell>
                                                        <TableCell>To Date/Time</TableCell>
                                                        <TableCell>Duration</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {loader ?
                                                        <TableRow>
                                                            <TableCell colSpan={10} >
                                                                <div className='text-center' style={{ height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Spinner animation="border" variant="danger" />
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        newAllStake.length > 0 ? (rowsPerPage > 0
                                                            ? newAllStake.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : newAllStake
                                                        ).map((row, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell>{row.WalletAddress}</TableCell>
                                                                <TableCell>{row.stakeAmount}</TableCell>
                                                                <TableCell>{new Date(row.start_Date).toLocaleString()}</TableCell>
                                                                <TableCell>{new Date((row.end_Date)).toLocaleString()}</TableCell>
                                                                <TableCell>{row.duration} months</TableCell>
                                                            </TableRow>
                                                        )) :
                                                            <TableRow>
                                                                <TableCell colSpan={10} ><h4 className='text-center' style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>No data found</h4></TableCell>
                                                            </TableRow>
                                                    }




                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                            colSpan={7}
                                                            count={newAllStake.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: {
                                                                    'aria-label': 'rows per page',
                                                                },
                                                                native: true,
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AllStaking