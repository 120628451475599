import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import Cookies from 'js-cookie';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetInvoiceChargeQuery } from '../../../redux/InvoiceChargeAPI'
import { useForm } from "react-hook-form";

function Funds({ cardShow, setCardShow, setGetPBMC, setCurrencyValue, setIsConfirm, p2pData, CurrentCurrency, fundType, lendingData }) {
    const { data } = useGetInvoiceChargeQuery()

    const accessToken = sessionStorage.getItem('accessToken')
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    //================ bank details modal ====================
    const [bankName, setBankName] = useState("")
    const [currency, setCurrency] = useState("")
    const [accountNum, setAccountNum] = useState("")
    const [correspondent, setCorrespondent] = useState("")
    const [beneficiary, setBeneficiary] = useState("")
    const [IBAN, setIBAN] = useState("")
    const [swift, setSwift] = useState("")
    const [transactionLoading, setTransactionLoading] = useState(false)
    const [showBankDetail, setShowBankDetail] = useState(false)

    const [chargeAmount, setChargeAmount] = useState({})

    const handleCloseBankDetail = () => {
        setShowBankDetail(false)
        setCardShow(false)
    }
    const handleEditClose = () => {
        setCardShow(false)
    }
    useEffect(() => {

        if (CurrentCurrency === "USD") {
            setBankName("INVESTEC BANK LIMITED")
            setBeneficiary("PBM INVESTOR SERVICES SA")
            setSwift("IVESZAJJ")
            setCurrency("USD")
            setAccountNum("1300224290504")
            setCorrespondent("CITIBANK NEW YORK")
            const saveData = data && data.getinvoiceCharge.find((ele) => ele.iso_code === CurrentCurrency)
            let symbol = saveData && saveData.invoiceChargesymbole
            let amount = saveData && saveData.invoiceChargeAmount
        }
        if (CurrentCurrency === "EUR") {
            setBankName("INVESTEC BANK LIMITED")
            setBeneficiary("PBM INVESTOR SERVICES SA")
            setSwift("IVESZAJJ")
            setCurrency("EUR")
            setAccountNum("1300224290502")
            setCorrespondent("COMMERZBANK FRANKFURT")

            const saveData = data && data.getinvoiceCharge.find((ele) => ele.iso_code === CurrentCurrency)
            let symbol = saveData && saveData.invoiceChargesymbole
            let amount = saveData && saveData.invoiceChargeAmount
        }
    }, [p2pData])
    //================ bank details modal ====================


    const onSubmit = (data) => {
        setTransactionLoading(true)
        let bodyData = {}
        if (fundType === "p2p_exchange") {
            bodyData = {
                "authId": p2pData.authId,
                "payment_type": p2pData.payment_type,
                "received_currency": p2pData.received_currency,
                "pbmc_amount": p2pData.pbmc_amount,
                "fundType": p2pData.fundType,
                "walletAddress": p2pData.walletAddress,
                "bank_Name": bankName,
                "beneficiary": beneficiary,
                "IBAN": IBAN,
                "bank_swift": swift,
                "transaction_id": data.transaction_id,
                "invoice_ChargeAmount": chargeAmount.amount
            }
        } else {
            bodyData = {
                "authId": lendingData.authId,
                "id": lendingData.id,
                "payment_type": lendingData.payment_type,
                "received_currency": lendingData.received_currency,
                "pbmc_amount": lendingData.pbmc_amount,
                "fundType": "loan",
                "walletAddress": lendingData.walletAddress,
                "loan_percentage": lendingData.loan_percentage,
                "apr_rate": lendingData.apr_rate,
                "pbmc_percentage": lendingData.pbmc_percentage,
                "loanTerm": lendingData.loanTerm,
                "bank_Name": bankName,
                "beneficiary": beneficiary,
                "IBAN": IBAN,
                "bank_swift": swift,
                "transaction_id": data.transaction_id,
                "invoice_ChargeAmount": chargeAmount.amount

            }
        }


        axios.post(`${process.env.REACT_APP_BASE_URL}/funds/addPayment`, bodyData,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            if (res.data) {
                setCardShow(false)
                setCurrencyValue("")
                setGetPBMC("")
                setTransactionLoading(false)
            }
            if (res.data.details.is_varified === 1) {
                setIsConfirm(false)
            }
            toast.success(' Your transaction successfully done', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            reset()
        })
            .catch((err) => {
                console.log(" p2p detail  err", err)
                setTransactionLoading(false)
            })

    }
    return (
        <div>
            <Modal show={cardShow}  onHide={handleEditClose} centered backdrop="static" keyboard={false} className='proceed_modal'>
                <Modal.Header closeButton>
                    <Modal.Title>P2P Exchange</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bankDetails_form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Bank Name</th>
                                        <td>{bankName}</td>
                                    </tr>
                                    <tr>
                                        <th>Bank (SWIFT / BIC)</th>
                                        <td>{swift}</td>
                                    </tr>
                                    <tr>
                                        <th>Beneficiary</th>
                                        <td>{beneficiary}</td>
                                    </tr>
                                    <tr>
                                        <th>Currency</th>
                                        <td>{currency}</td>
                                    </tr>
                                    <tr>
                                        <th>Account number</th>
                                        <td>{accountNum}</td>
                                    </tr>
                                    <tr>
                                        <th>Investec USD Correspondent</th>
                                        <td>{correspondent}</td>
                                    </tr>
                                    <tr>
                                        <th>Transaction ID</th>
                                        <td>
                                            <input type="text" className='form-control' placeholder='Enter transaction id of payment' disabled={transactionLoading}
                                                {...register("transaction_id", {
                                                    required: "Transaction ID is required",
                                                })}
                                            />
                                            {errors.transaction_id && <small className='error_msg_class ps-0'>{errors.transaction_id.message}</small>}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>


                            <div className="send_btn d-flex justify-content-end">
                                {transactionLoading ?
                                    <button className='btn btn-success px-4'>
                                        <div className="spinner-border spinner-border-sm text-light" role="status"></div>
                                    </button>
                                    :
                                    <button className='btn btn-success' type='submit'>Send</button>
                                }
                            </div>
                        </form>
                    </div>

                </Modal.Body>

            </Modal>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default Funds