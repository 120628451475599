import React, { useState, useEffect, useRef } from 'react'
import { Table, Container, Row, Col, Image, Button, Modal, Spinner } from 'react-bootstrap'
import InvoiceDetails from '../../../../style/images/dashLogo_black.png'
import CalenderIcon from '../../../../style/images/calender.svg'
import { Link } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdOutlineContentCopy } from 'react-icons/md'
import { MINT_ABI } from '../../../../contract/Mint'
import { PBMC_ABI } from '../../../../contract/ERC20_ABI'
import Web3 from 'web3'
import { useSelector, useDispatch } from 'react-redux'
import { updatePBMCValue } from '../../../../redux/reducer'
import copy from 'copy-to-clipboard'
const web3 = new Web3(window.ethereum);
function Invoice_payment_view() {
    const WalletAddress = useSelector(state => state.walletBalance.walletAddress)
    const dispatch = useDispatch()
    const OpenMetamask = useSelector(state => state.user.updatePBMCAmount)
    const ABI = MINT_ABI
    const Mint_ContractAddress = process.env.REACT_APP_MINT_CONTRACT_ADDRESS
    const mint = new web3.eth.Contract(ABI, Mint_ContractAddress);

    const [MintLoader, setMintLoader] = useState(false)
    const [MintHash, setMintHash] = useState("")
    const InvoiceRef = useRef(null)

    const [mintUpdate, setMintUpdate] = useState(false)

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { slug } = useParams()
    const accessToken = sessionStorage.getItem('accessToken')
    const [LoginType, setLoginType] = useState(null)
    const [invoiceData, setInvoiceData] = useState()
    const [invoiceState, setInvoiceState] = useState(false)
    const [TransactionId, setTransactionId] = useState('')
    const [Copy, setCopy] = useState(false)
    const [depositNFTLoader, setDepositNFTLoader] = useState(false)
    const [BurnNFTLoader, setBurnNFTLoader] = useState(false)
    const [checkCopy, setCheckCopy] = useState(NaN)

    // ====================================== COPY TRANSACTION ID ======================================
    const handleCopy = (transactionId, event, data) => {
        copy(transactionId);
        setCheckCopy(true)
        setTimeout(() => {
            setCheckCopy(false)
        }, 1000);
    }
    // ====================================== COPY TRANSACTION ID ======================================
    useEffect(() => {
        const login_type = sessionStorage.getItem('login_type')
        setLoginType(login_type)
    }, [])

    const getInvoiceData = async () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/invoiceListbyUser/${slug}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {

                setInvoiceData(res.data)
                const x = `${res.data.details.order_id.slice(0, 4)}...${res.data.details.order_id.slice(-4)}`;
                setTransactionId(x)

            })
            .catch((err) => {
                console.log("admin ivoice detail err---", err)
            })
    }
    useEffect(() => {
        getInvoiceData()
    }, [invoiceState, mintUpdate])

    function handleCopied() {
        setCopy(true)
        setTimeout(() => {
            setCopy(false)
        }, 2000);
    }

    //========= approve api start=======
    const handleAccept = () => {
        let formData = {
            status: "approved",
            id: slug
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/kyc/paymentApproveReject`, formData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                if (res) {

                    toast.success('invoice request approved successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        setInvoiceState(!invoiceState)
                    }, 2200)
                }
            })
            .catch((err) => {
                console.log("status err---", err)
            })
    }
    //========= approve api end =======

    //========= reject api start=======
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        reset()
    }
    const handleShow = () => setShow(true);
    const onSubmit = (data) => {
        let formData = {
            reasons: data.reason,
            status: "rejected",
            id: slug
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/kyc/paymentApproveReject`, formData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                if (res) {
                    toast.success('invoice request rejected successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setShow(false)
                    setTimeout(() => {
                        setInvoiceState(!invoiceState)
                    }, 2200)
                }
            })
            .catch((err) => {
                console.log("status err---", err)
            })
    }
    //========= reject api end=======

    // ============================================== mINT =================================================

    function Mint() {
        if (WalletAddress !== "NA") {
            setMintLoader(true)
            mint.methods.mint(invoiceData.details.document_image).send({ from: WalletAddress }).then(e => {
                const _tokenId = e.events.MetadataUpdate.returnValues._tokenId
                setMintHash(e.transactionHash)
                setMintLoader(false)

                let bodyData = {
                    "id": invoiceData.details._id,
                    "action_status": true,
                    "transaction_hash": e.transactionHash,
                    "tokenId": _tokenId
                }
                sendMint(bodyData)

            }).catch(e => {
                console.log(e);
                setMintLoader(false)
            })
        } else {
            dispatch(updatePBMCValue(!OpenMetamask))
        }
    }

    // ============================================== mINT  =================================================


    function sendMint(bodyData) {
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/kyc/invoice-transation`, bodyData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {

            if (res) {
                setMintUpdate(!mintUpdate)
            }
        }).catch((err) => {
            console.log("err---", err)
        })
    }


    const [PBMCAdminVal, setPBMCAdminVal] = useState(null)
    // ================================================ GET Currency Real time Value ========================================================= 
    const getPbmcValue = async () => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getPBM_Coin`,
        ).then((res) => {
            console.log("PBMC COin value", res.data.PBMCoin[0].coin_price)
            setPBMCAdminVal(res.data.PBMCoin[0].coin_price)
        }).catch((err) => {
            console.log("pbm res err", err)
        })
    }


    useEffect(() => {
        getPbmcValue()
    }, [])

    // ====================================== Deposit ========================================================
    const contractAddress = process.env.REACT_APP_MINT_CONTRACT_ADDRESS
    const PBMC_Contract = process.env.REACT_APP_CONTRACT_ADDRESS
    const token = new web3.eth.Contract(ABI, contractAddress);
    const pbmc = new web3.eth.Contract(PBMC_ABI, PBMC_Contract);

    async function depositNFT() {
        if (WalletAddress !== "NA") {
            const seller = invoiceData.details.walletAddress;
            const tokenId = invoiceData.details.tokenId;
            const Amount = invoiceData.details.collateral_amount.toString()
            const pbmcAmount = Math.floor(Amount * 10 ** 14).toString()
            const NFTPrice = Math.floor(((invoiceData.details.invoiceAmount / PBMCAdminVal) * 10 ** 14).toString());
            const Allowance = await pbmc.methods.allowance(WalletAddress, contractAddress).call()
            setDepositNFTLoader(true)
            if (Allowance < pbmcAmount) {
                try {
                    await pbmc.methods.approve(contractAddress, pbmcAmount).send({ from: WalletAddress }).then(async e => {
                        const nft = await token.methods.depositCollateral(seller, tokenId, pbmcAmount, NFTPrice).send({ from: WalletAddress })
                        setDepositNFTLoader(false)
                        const DepositData = {
                            "id": invoiceData.details._id,
                            "admintransaction_hash": nft.transactionHash
                        }
                        axios.put(`${process.env.REACT_APP_BASE_URL}/v1/kyc/collateral-deposit`, DepositData, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }).then(data => {
                            setMintUpdate(!mintUpdate)
                        }).catch(error => {
                            console.log(error);
                        })
                    }).catch(e => {
                        console.log(e);
                    })
                } catch (error) {
                    setDepositNFTLoader(false)
                    console.log(error);
                }
            } else {
                try {
                    const nft = await token.methods.depositCollateral(seller, tokenId, pbmcAmount, NFTPrice).send({ from: WalletAddress })
                    setDepositNFTLoader(false)
                    const DepositData = {
                        "id": invoiceData.details._id,
                        "admintransaction_hash": nft.transactionHash
                    }
                    axios.put(`${process.env.REACT_APP_BASE_URL}/v1/kyc/collateral-deposit`, DepositData, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }).then(data => {
                        setMintUpdate(!mintUpdate)
                    }).catch(error => {
                        console.log(error);
                    })
                } catch (error) {
                    setDepositNFTLoader(false)
                    console.log(error);
                }
            }
        } else {
            dispatch(updatePBMCValue(!OpenMetamask))
        }
    }
    // ====================================== Deposit ========================================================

    // ========================================= BURN ==========================================================
    async function BurnNFT() {
        try {
            if (WalletAddress !== "NA") {
                setBurnNFTLoader(true)
                const tokenId = invoiceData.details.tokenId;
                await token.methods.burnNft(tokenId).send({ from: WalletAddress }).then(a => {
                    setBurnNFTLoader(false)
                    const BuyData = {
                        "action_status": true,
                        "id": invoiceData.details._id,
                        "burntransaction_hash": a.transactionHash
                    }
    
                    axios.put(`${process.env.REACT_APP_BASE_URL}/v1/kyc/collateral-barn`, BuyData, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }).then(data => {
                        setMintUpdate(!mintUpdate)
                    }).catch(error => {
                        console.log(error);
                    })
                }).catch(error => {
                    console.log(error);
                })
            } else {
                dispatch(updatePBMCValue(!OpenMetamask))
            }
        } catch (error) {
            setBurnNFTLoader(false)
            console.log(error);
        }
    }
    // ========================================= BURN ==========================================================

    return (
        <>
            <div className="invoice_details_section">
                <Container fluid className='px-0'>
                    <Row className='justify-content-center'>
                        <Col lg={12} md={12} sm={12}>
                            <div ref={InvoiceRef} className="invoice_detail_content">
                                <div className="invoice_title">
                                    <div className="back_btn"><Link to="/dashboard/invoice_payment"><BiArrowBack /></Link></div>
                                    <div className='invoice_head'>
                                        <div className='head'>
                                            <div className='invoice_head_icon'>
                                                <h6>Invoice Payment Details</h6>
                                            </div>
                                            <div className='invoice_head_content'>
                                                {
                                                    invoiceData && invoiceData.details.status === "approved" ?
                                                        MintLoader ?
                                                            <Button variant="warning" className="px-4" style={{ borderRadius: "20px", fontWeight: "500" }} ><Spinner variant='light' size='sm' /></Button>
                                                            :
                                                            invoiceData.details.transaction_hash && invoiceData.details.transaction_hash.length > 0 ?
                                                                <a target='_blank' href={`https://etherscan.io/tx/${invoiceData.details.transaction_hash}`}>View on Etherscan</a>
                                                                :
                                                                <Button variant="warning" className="px-4" onClick={Mint} style={{ borderRadius: "20px", fontWeight: "500" }} >Mint</Button>
                                                        :

                                                        (invoiceData && invoiceData.details.status === "approved" || invoiceData && invoiceData.details.status === "rejected") ?
                                                            " " :
                                                            <Button variant="success" className="px-4" style={{ borderRadius: "20px" }} onClick={handleAccept}>Accept</Button>


                                                }
                                                {
                                                    (invoiceData && invoiceData.details.status === "approved" || invoiceData && invoiceData.details.status === "rejected") ?
                                                        "" :
                                                        <Button variant="danger" className="ms-3 px-4" style={{ borderRadius: "20px" }} onClick={handleShow}>Reject</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    invoiceData &&
                                    <div className='invoice_payment_details'>
                                        <Container fluid>
                                            <Row>
                                                <Col lg={6}>
                                                    <InvoiceDetailTable
                                                        text_key={
                                                            [
                                                                {
                                                                    name: "Payment/Transaction ID :",
                                                                    data: invoiceData.details.order_id,
                                                                    type: "tnx_id"
                                                                },
                                                                {
                                                                    name: "Invoice Number :",
                                                                    data: invoiceData.details.invoiceNumber
                                                                },
                                                                {
                                                                    name: "Invoice Amount :",
                                                                    data: invoiceData.details.invoiceAmount + " " + invoiceData.details.iso_codeInvoice
                                                                },
                                                                {
                                                                    name: "Supplier Name :",
                                                                    data: invoiceData.supplierDetail.authId.first_Name + " " + invoiceData.supplierDetail.authId.last_Name
                                                                },
                                                                {
                                                                    name: "Buyer Name :",
                                                                    data: invoiceData.details.invoiceBuyer_Name
                                                                },
                                                            ]
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <InvoiceDetailTable
                                                        text_key={
                                                            [
                                                                {
                                                                    name: "Wallet address :",
                                                                    data: invoiceData.details.walletAddress
                                                                },
                                                                {
                                                                    name: "Collateral Amount :",
                                                                    data: invoiceData.details.collateral_amount !== undefined ? `${invoiceData.details.collateral_amount} PBMC` : '-'
                                                                },
                                                                {
                                                                    name: "Collateral Status :",
                                                                    data: invoiceData.details.collateral_status
                                                                },
                                                                {
                                                                    name: "Deposit Status :",
                                                                    data: invoiceData.details.deposit_Status
                                                                },
                                                                {
                                                                    name: "Burn Status :",
                                                                    data: invoiceData.details.burn_status
                                                                },
                                                            ]
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <InvoiceDetailTable
                                                        text_key={
                                                            [
                                                                {
                                                                    name: "Submission Fee  :",
                                                                    data: invoiceData.details.invoice_ChargeAmount + " " + invoiceData.details.iso_codeInvoice
                                                                },
                                                                {
                                                                    name: "Invoice Status :",
                                                                    data: invoiceData.details.invoiceStatus
                                                                },
                                                                {
                                                                    name: "Document Type :",
                                                                    data: invoiceData.details.document_image,
                                                                    type: "link"
                                                                },
                                                                {
                                                                    name: "Mint Status :",
                                                                    data: invoiceData.details.mint_status
                                                                },
                                                                {
                                                                    name: "Deposit NFT :",
                                                                    data: "deposit",
                                                                    collateral: invoiceData.details.collateral_status,
                                                                    mint: invoiceData.details.mint_status,
                                                                    txhash: invoiceData.details.admintransaction_hash
                                                                },
                                                                {
                                                                    name: "Burn NFT :",
                                                                    data: "burn",
                                                                    status: invoiceData.details.burn_status,
                                                                    txhash: invoiceData.details.investor_by_transactionhash,
                                                                    burnTx: invoiceData.details.burntransaction_hash
                                                                },
                                                            ]
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <InvoiceDetailTable
                                                        text_key={
                                                            [
                                                                {
                                                                    name: "Issue date :",
                                                                    data: invoiceData.details.createdAt
                                                                },
                                                                {
                                                                    name: "Supplier mail :",
                                                                    data: invoiceData.supplierDetail.authId.email
                                                                },
                                                                {
                                                                    name: "Supplier contact no :",
                                                                    data: invoiceData.supplierDetail.authId.phoneNumber
                                                                },
                                                                {
                                                                    name: "Buyer mail :",
                                                                    data: invoiceData.details.buyer_email
                                                                },
                                                                {
                                                                    name: "Buyer contact no :",
                                                                    data: invoiceData.details.invoiceBuyer_Contact
                                                                }
                                                            ]
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                }


                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={show} onHide={handleClose} centered className='reject_modal'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reason for Reject</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <textarea className="form-control" rows={6}
                                        {...register("reason", {
                                            required: "Reason is required",
                                            pattern: {
                                                value: /^[^\s].+[^\s]/,
                                                message: "Space not allowed"
                                            },
                                        })} ></textarea>
                                    {errors.reason && <small className='error_msg_class ps-0'>{errors.reason.message}</small>}
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' className="px-4" onClick={handleClose} style={{ borderRadius: "20px" }}>
                            Cancel
                        </Button>
                        <Button variant="danger" className="ms-3 px-4" type='submit' style={{ borderRadius: "20px" }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
    function InvoiceDetailTable({ text_key }) {
        return (
            <div className='invoice_details_container'>
                <Table>
                    <tbody>
                        {text_key.map(({ name, data, status, collateral, mint, type, txhash, burnTx }, key) => (
                            <tr key={key}>
                                <td>{name}</td>
                                <td>
                                    {type === "link" ?
                                        <a target='_blank' href={data}>View Document</a>
                                        :
                                        data === "deposit" ?
                                            (collateral === 'pending' || mint === 'pending') ?
                                                <button className='btn btn-primary' disabled>Deposit</button>
                                                :
                                                txhash ?
                                                    <a target='_blank' href={`https://etherscan.io/tx/${txhash}`}>view on etherscan</a>
                                                    :
                                                    depositNFTLoader ? <button className='btn btn-primary' style={{ padding: "5px 16px" }}><Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner></button> :
                                                        <button className='btn btn-primary' onClick={depositNFT}>Deposit</button>
                                            : data === "burn" ?
                                                txhash ?
                                                    status === "success" ?
                                                        <a target='_blank' href={`https://etherscan.io/tx/${burnTx}`}>view on etherscan</a>
                                                        :
                                                        BurnNFTLoader ? <button className='btn btn-primary' style={{ padding: "5px 16px" }}><Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner></button> :
                                                            <button className='btn btn-primary' onClick={BurnNFT}>Burn</button>
                                                    :
                                                    <button className='btn btn-primary' disabled>Burn</button>
                                                : name === "Issue date :" ?
                                                    new Date(data).toDateString()
                                                    :
                                                    type === "tnx_id" ?
                                                        <>
                                                            <div className='lending_transaction' onClick={(event) => handleCopy(data, event)}>{data.slice(0, 4)}...{data.slice(-4)}
                                                                {checkCopy === true ? <span className='copy_text'>Copied</span> : ""}
                                                            </div>
                                                        </>

                                                        : data
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div >
        )
    }
}

export default Invoice_payment_view

