import React, { useEffect, useState } from 'react'
import { Table, Container, Row, Col, Tab, Tabs, Image, Accordion, Button, Modal } from 'react-bootstrap'
import Web3 from 'web3'
import { HiOutlineAdjustmentsHorizontal } from 'react-icons/hi2'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import PoolLogo from '../../../style/images/pbmc_logo.png'
import { useSelector } from 'react-redux'

//=========================================== TOKEN ABI ========================================================================

import { PBMC_ABI } from '../../../contract/ERC20_ABI'
import { usdAbi } from '../../../contract/ERC20_ABI1'
import { btcAbi } from '../../../contract/BTC'

//=========================================POOL ABI =============================================================================

import { pbmcUsdtAbi } from '../../../contract/PBMC_USDT'

import { pbmc_eth_abi } from '../../../contract/PBMC_ETH'

import { pbmc_btc_abi } from '../../../contract/PBMC_BTC'





function MainLiquidity() {
    const web3 = new Web3(window.ethereum);
    // ========================== WALLET ADDRESS ==========================
    let WalletAddress = useSelector(state => state.walletBalance.walletAddress)





    // ========================== CONTRACT ADDRESSES ==================================================================================


    const PBMC_CONTRACT = process.env.REACT_APP_CONTRACT_ADDRESS

    const USD_CONTRACT = process.env.REACT_APP_usd_CONTRACT_ADDRESS

    const BTC_CONTRACT = process.env.REACT_APP_btc_CONTRACT_ADDRESS

    const USD_PBMC_CONTRACT = process.env.REACT_APP_usdPBMC_CONTRACT_ADDRESS

    const ETH_PBMC_contract_address = process.env.REACT_APP_ethPBMC_CONTRACT_ADDRESS

    const BTC_PBMC_contract_address = process.env.REACT_APP_btcPBMC_CONTRACT_ADDRESS

    // ===================================================== TOKEN INSTANCES  ==========================================================
    const PBMC_INSTANCE = new web3.eth.Contract(PBMC_ABI, PBMC_CONTRACT)

    const USD_INSTANCE = new web3.eth.Contract(usdAbi, USD_CONTRACT)

    const BTC_INSTANCE = new web3.eth.Contract(btcAbi, BTC_CONTRACT) // might change abi

    //==========================================  LIQUIDITY POOL INSTANCES ==============================================================  

    const USD_POOL_INSTANCE = new web3.eth.Contract(pbmcUsdtAbi, USD_PBMC_CONTRACT)

    const ETH_POOL_INSTANCE = new web3.eth.Contract(pbmc_eth_abi, ETH_PBMC_contract_address)

    const BTC_POOL_INSTANCE = new web3.eth.Contract(pbmc_btc_abi, BTC_PBMC_contract_address)




    // ========================== STATES ===================================================================================================
    const [pbmcValue, setPBMCValue] = useState("")
    const [pbmcError, setPBMCError] = useState("")
    const [tokenValue, setTokenValue] = useState("")
    const [tokenName, setTokenName] = useState("")
    const [swapName, setSwapName] = useState("")
    const [swapCoin, setSwapCoin] = useState("")
    const [swapAmount, setSwapAmount] = useState("")
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [showAddLiquidity, setShowAddLiquidity] = useState(false);
    const handleCloseLiquidity = () => setShowAddLiquidity(false);
    const [showSwap, setShowSwap] = useState(false);
    const handleCloseSwap = () => setShowSwap(false);

    // ========================== OPEN SWAP POPUP ==========================
    const handleSwap = (id) => {
        setSwapName(id)
        setShowSwap(true)
    }
    // ========================== OPEN Add Liquidity POPUP ==========================
    const handleAddLiquidity = async (id) => {
        setTokenName(id)
        setShowAddLiquidity(true)
        setPBMCError("")
    }
    const addToken = async () => {
        try {
            if (pbmcValue) {
                setPBMCError("")
                //==================================== USDT-PBMC POOL ==============================================================================
                if (tokenName === "USDT") {

                    const tokenValueString = (tokenValue * 1000000).toString()

                    const PBMC_Allowance1 = await PBMC_INSTANCE.methods.allowance(USD_PBMC_CONTRACT, WalletAddress).call()     // CHECKING PBMC ALLOWANCE


                    const USDT_Allowance = await USD_INSTANCE.methods.allowance(USD_PBMC_CONTRACT, WalletAddress).call()      //CHECKING USDT ALLOWANCE

                    await PBMC_INSTANCE.methods.approve(USD_PBMC_CONTRACT, pbmcValue).send({ from: WalletAddress })         //APPROVING PBMC
                    await USD_INSTANCE.methods.approve(USD_PBMC_CONTRACT, tokenValueString).send({ from: WalletAddress })        //APPROVING USDT


                    const USDT_PBMC_poolTxn = await USD_POOL_INSTANCE.methods.addLiquidity(pbmcValue, tokenValueString, WalletAddress).send({ from: WalletAddress })     // ADDING LIQUIDITY FUNCTION CALL
                }

                //==================================== ETH-PBMC POOL ==============================================================================

                if (tokenName === "ETH") {

                    const PBMC_Allowance2 = await PBMC_INSTANCE.methods.allowance(ETH_PBMC_contract_address, WalletAddress).call()     // CHECKING PBMC ALLOWANCE 

                    await PBMC_INSTANCE.methods.approve(ETH_PBMC_contract_address, pbmcValue).send({ from: WalletAddress })         //APPROVING PBMC
                    const tokenValueString = (tokenValue).toString()
                    const ethValue = web3.utils.toWei(tokenValueString)
                    // const pbmcValueWei = web3.utils.toWei(pbmcValue)
                    const ETH_PBMC_poolTxn = await ETH_POOL_INSTANCE.methods.addLiquidityETH(pbmcValue, WalletAddress).send({ from: WalletAddress, value: ethValue }) // ADDING LIQUIDITY IN ETH-PBMC POOL

                    // const ETH_PBMC_poolTxn= await ETH_POOL_INSTANCE.methods.userToMint(WalletAddress).call()

                    // const ETH_PBMC_poolTxn= await ETH_POOL_INSTANCE.methods.removeLiquidityETH("474046411229955413", WalletAddress).send({ from: WalletAddress})


                }

                //==================================== BTC-PBMC POOL ==============================================================================

                if (tokenName === "BTC") {
                    // const pbmcValueWei = web3.utils.toWei(pbmcValue)

                    const tokenValueString = (tokenValue).toString()
                    const btcValue = web3.utils.toWei(tokenValueString)

                    const PBMC_Allowance3 = await PBMC_INSTANCE.methods.allowance(BTC_PBMC_contract_address, WalletAddress).call()     // CHECKING PBMC ALLOWANCE


                    const BTC_Allowance = await BTC_INSTANCE.methods.allowance(BTC_PBMC_contract_address, WalletAddress).call()      //CHECKING BTC ALLOWANCE


                    await PBMC_INSTANCE.methods.approve(BTC_PBMC_contract_address, pbmcValue).send({ from: WalletAddress })         //APPROVING PBMC


                    await BTC_INSTANCE.methods.approve(BTC_PBMC_contract_address, btcValue).send({ from: WalletAddress })        //APPROVING BTC


                    const BTC_PBMC_poolTxn = await BTC_POOL_INSTANCE.methods.addLiquidity(pbmcValue, btcValue, WalletAddress).send({ from: WalletAddress })     // ADDING LIQUIDITY FUNCTION CALL


                }
            } else {
                setPBMCError("Please Enter Amount")
            }
        } catch (error) {
            console.log(error);
        }

    }

    const addSwap = async () => {
        try {
            //===============================================================USDT-PBMC-SWAP============================================================================
            if (swapName === "USDT") {

                //------------------------------------------------USDT TO PBMC---------------------------------------------------
                if (swapCoin === "usd") {

                    //approving USDT
                    const swapAmountToString = (swapAmount * 1000000).toString()
                    const approve_USDT = await USD_INSTANCE.methods.approve(USD_PBMC_CONTRACT, swapAmountToString).send({ from: WalletAddress })

                    //SWAPPING USDT to PBMC

                    const swap_USDT_to_PBMC = await USD_POOL_INSTANCE.methods.swapUSDTForPBMC(swapAmountToString, WalletAddress).send({ from: WalletAddress })
                }

                //------------------------------------------------PBMC TO USDT---------------------------------------------------
                if (swapCoin === "pbmc") {

                    //approving PBMC
                    const pbmcAmountString = swapAmount.toString()
                    const pbmcAmountWei =(pbmcAmountString * 10 **14).toString()
                    await PBMC_INSTANCE.methods.approve(USD_PBMC_CONTRACT, pbmcAmountWei).send({ from: WalletAddress })

                    //Swapping PBMC TO USDT

                    const swap_PBMC_to_USDT = await USD_POOL_INSTANCE.methods.swapPBMCForUSDT(pbmcAmountWei, WalletAddress).send({ from: WalletAddress })
                }
            }

            //===============================================================ETH-PBMC-SWAP============================================================================

            if (swapName === "ETH") {

                //------------------------------------------------ETH TO PBMC---------------------------------------------------
                if (swapCoin === "eth") {

                    const swapETHString = swapAmount.toString()
                    const swapETH_Amount = toWei(swapETHString)
                    const swap_ETH_to_PBMC = await ETH_POOL_INSTANCE.methods.swapExactETHForTokens(WalletAddress).send({ from: WalletAddress, value: swapETH_Amount })
                }

                //---------------------------------------------------PBMC TO ETH-----------------------------------------------------
                if (swapCoin === "pbmc") {

                    //approving PBMC
                    const pbmcAmountString1 = swapAmount.toString()
                    const pbmcAmountWei1 = (pbmcAmountString1* 10 **14).toString()
                    await PBMC_INSTANCE.methods.approve(ETH_PBMC_contract_address, pbmcAmountWei1).send({ from: WalletAddress })

                    //Swapping PBMC TO ETH

                    const swap_PBMC_to_ETH = await ETH_POOL_INSTANCE.methods.swapExactTokensForETH(pbmcAmountWei1, WalletAddress).send({ from: WalletAddress })

                }
            }

            //============================================================ BTC PBMC SWAP ========================================================================
            if (swapName === "BTC") {

                //------------------------------------------------BTC TO PBMC---------------------------------------------------
                if (swapCoin === 'btc') {

                    //approving btc
                    const btcAmountString = swapAmount.toString()
                    const btcAmountWei = web3.utils.toWei((btcAmountString * 10 **8).toString())
                    await BTC_INSTANCE.methods.approve(BTC_PBMC_contract_address, btcAmountWei).send({ from: WalletAddress })

                    //Swapping BTC to PBMC
                    const swap_BTC_to_PBMC = await BTC_POOL_INSTANCE.methods.swapWBTCForPBMC(btcAmountWei, WalletAddress).send({ from: WalletAddress })
                }

                //------------------------------------------------PBMC TO BTC---------------------------------------------------
                if (swapCoin === 'pbmc') {
                    //approving PBMC
                    const pbmcAmountString2 = swapAmount.toString()
                    const pbmcAmountWei2 = (pbmcAmountString2* 10 **14).toString()
                    await PBMC_INSTANCE.methods.approve(BTC_PBMC_contract_address, pbmcAmountWei2).send({ from: WalletAddress })

                    //Swapping PBMC to BTC
                    const swap_PBMC_to_BTC = await BTC_POOL_INSTANCE.methods.swapPBMCForWBTC(pbmcAmountWei2, WalletAddress).send({ from: WalletAddress })

                }

            }



        } catch (error) {
            console.log("error", error);
        }
    }

    const handlePBMCAmount = ({ target: { value } }) => {
        const toWeiAmount = value !== '' && (value*10**14).toString()
        setPBMCValue(toWeiAmount)

    }
    const handleTokenAmount = ({ target: { value } }) => {
        setTokenValue(value)
    }
    const swapAmountChange = ({ target: { value } }) => {
        setSwapAmount(value)
    }
    const swapCoinChange = ({ target: { value } }) => {
        setSwapCoin(value)
    }
    function toWei(tokenAmount) {
        return (Web3.utils.toWei(tokenAmount)).toString();
    }
    return (
        <>
            <section className='liquidity_pool mt-4'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='banner'>
                                <h2>Liquidity Pool</h2>
                                <p>Liquidity pool is a smart contract containing large portion of digital assets locked up to
                                    provide liquidity to facilitate decentralized trades. It allows digital assets to be transacted
                                    efficiently and provides users with spare digital assets to profit from the usage by other users.
                                </p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='main_details'>
                                <div className='pool_bar'>
                                    <Tabs
                                        defaultActiveKey="Live"
                                        id="uncontrolled-tab-example"
                                        className="mb-3 mt-3"
                                    >
                                        <Tab eventKey="Live" title="Live">
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className='pool_box'>
                                                        <div className='top_section'>
                                                            <div className='content'>
                                                                <h4>PBMC/ USDT POOL</h4>
                                                            </div>
                                                            <div className='pool_logo'>
                                                                <Image src={PoolLogo} fluid loading="lazy"/>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='mid_section'>
                                                            <div className='list_bar'>
                                                                <div className='apr'>Fees</div>
                                                                <div className='percentage'>2% <HiOutlineAdjustmentsHorizontal /></div>
                                                            </div>
                                                            <div className='start_earning'>Start Earning</div>
                                                        </div>
                                                        <hr />
                                                        <div className='bottom_section'>
                                                            <Accordion>
                                                                <Accordion.Item eventKey="0">
                                                                    <Accordion.Header>Details <MdOutlineKeyboardArrowDown /></Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <Table responsive>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Token 1</td>
                                                                                    <td className='property_value'> PBMC</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Token 2</td>
                                                                                    <td className='property_value'> USDT</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Liquidity Share</td>
                                                                                    <td className='property_value'> Shares</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <button className='btn btn-warning swap_btn' onClick={() => handleSwap("USDT")} disabled>Swap</button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='add_liquidity_btn'><button className='btn btn-success' onClick={() => handleAddLiquidity("USDT")} disabled>Add Liquidity</button></div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className='pool_box'>
                                                        <div className='top_section'>
                                                            <div className='content'>
                                                                <h4>PBMC/ ETH POOL</h4>
                                                            </div>
                                                            <div className='pool_logo'>
                                                                <Image src={PoolLogo} fluid loading="lazy"/>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='mid_section'>
                                                            <div className='list_bar'>
                                                                <div className='apr'>Fees</div>
                                                                <div className='percentage'>2% <HiOutlineAdjustmentsHorizontal /></div>
                                                            </div>

                                                            <div className='start_earning'>Start Earning</div>
                                                        </div>
                                                        <hr />
                                                        <div className='bottom_section'>
                                                            <Accordion>
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>Details <MdOutlineKeyboardArrowDown /></Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <Table responsive>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Token 1</td>
                                                                                    <td className='property_value'> PBMC</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Token 2</td>
                                                                                    <td className='property_value'> ETH</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Liquidity Share</td>
                                                                                    <td className='property_value'> Shares</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <button className='btn btn-warning swap_btn' onClick={() => handleSwap("ETH")} disabled>Swap</button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='add_liquidity_btn'><button className='btn btn-success' onClick={() => handleAddLiquidity("ETH")} disabled>Add Liquidity</button></div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className='pool_box'>
                                                        <div className='top_section'>
                                                            <div className='content'>
                                                                <h4>PBMC/ BTC POOL</h4>
                                                            </div>
                                                            <div className='pool_logo'>
                                                                <Image src={PoolLogo} fluid loading="lazy"/>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='mid_section'>
                                                            <div className='list_bar'>
                                                                <div className='apr'>Fees</div>
                                                                <div className='percentage'>2% <HiOutlineAdjustmentsHorizontal /></div>
                                                            </div>
                                                            <div className='start_earning'>Start Earning</div>
                                                        </div>
                                                        <hr />
                                                        <div className='bottom_section'>
                                                            <Accordion>
                                                                <Accordion.Item eventKey="2">
                                                                    <Accordion.Header>Details <MdOutlineKeyboardArrowDown /></Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <Table responsive>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Token 1</td>
                                                                                    <td className='property_value'> PBMC</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Token 2</td>
                                                                                    <td className='property_value'> BTC</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Liquidity Share</td>
                                                                                    <td className='property_value'> Shares</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <button className='btn btn-warning swap_btn' onClick={() => handleSwap("BTC")} disabled>Swap</button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='add_liquidity_btn'><button className='btn btn-success' onClick={() => handleAddLiquidity("BTC")} disabled>Add Liquidity</button></div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* ======================add liquidity modal============================ */}
            <Modal show={showAddLiquidity} onHide={handleCloseLiquidity} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Liquidity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="add_liquidity_form">
                        <div className='liquidity_popup_wrap'>
                            <div className='liquidity_popup'>
                                <div className='liquidity_input'>
                                    <label htmlFor="">Enter PBMC</label>
                                    <input type="number" className='form-control' onChange={handlePBMCAmount} />
                                    <small className="text-danger">{pbmcError}</small>
                                </div>
                                <div className='liquidity_btn_wrap'>
                                </div>
                            </div>
                            <div className='liquidity_popup'>
                                <div className='liquidity_input'>
                                    <label htmlFor="">Enter {tokenName}</label>
                                    <input type="number" className='form-control' onChange={handleTokenAmount} />
                                </div>
                                <div className='liquidity_btn_wrap'>
                                </div>
                            </div>
                            <div className='liquidity_add_btn_wrap'>
                                <button className='btn liquidity_add_btn' onClick={addToken}>Add</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ======================swap modal============================ */}
            <Modal show={showSwap} onHide={handleCloseSwap} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Swap</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="add_liquidity_form">
                        <div className='liquidity_popup_wrap'>
                            <div className='liquidity_element'>
                                <label htmlFor="">Select Coin</label>
                                <select className='form-select' onChange={swapCoinChange}>
                                    <option value="">Select</option>
                                    <option value="pbmc">PBMC</option>
                                    <option value="usd">USD</option>
                                    <option value="eth">ETH</option>
                                    <option value="btc">wBTC</option>

                                </select>
                            </div>
                            <div className='liquidity_element'>
                                <label htmlFor="">Amount</label>
                                <input type="number" className='form-control' onChange={swapAmountChange} />
                            </div>
                            <div className='liquidity_btn_wrap'>
                                <button className='btn liquidity_btn' onClick={addSwap}>Swap</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MainLiquidity