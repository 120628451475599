import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Image } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

export default function TwoFactorAuth() {
    const [QR, setQR] = useState('')
    const [Success, setSuccess] = useState(false)
    const [Error, setError] = useState(false)
    const [ShowSecret, setShowSecret] = useState("")
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const { _id } = JSON.parse(sessionStorage.getItem('loggedInUserData'))
    const navigation = useNavigate()
    // ========================================== Get QR ==============================================
    async function getQr() {
        try {
            const { data: { QRCode, secret } } = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/2FA_Auth-enable`, { id: _id })
            setQR(QRCode);
            setShowSecret(secret)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getQr()
    }, [])


    // ======================================= Verify 2FA OTP =============================================
    const onSubmit = (data) => {
        const bodyData = {
            "id": _id,
            "otpToken": data.two_factor
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/2FA_Auth-verify`, bodyData).then(e => {
            console.log(e)
            if (e.data.status === 200) {
                sessionStorage.setItem('accessToken', e.data.token)
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                    reset()
                    navigation('/dashboard/home')
                }, 3000);
            }
        }
        ).catch(({ response: { data } }) => {
            console.log(data)
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
        )
    }
    return (
        <>
            <div className="login_section">
                <div className='text_bg'>
                    <div className='text'>DEFI <br></br> FINANCE</div>
                    <div className='text'>SECURE <br></br> TRUSTED  </div>
                </div>
                <Container>
                    <Row className=''>
                        <Col xl={5} lg={7} md={8} sm={12}>
                            <div className='login_wrapper'>
                                <div className='login_box'>
                                    {Success &&
                                        <div className="success_message text-center">
                                            <p>{'2FA Verification successfully done!'}</p>
                                        </div>
                                    }
                                    {
                                        Error &&
                                        <div className="account_err text-center">
                                            <p>{'Please Enter Correct OTP!'}</p>
                                        </div>
                                    }
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h5 className='otp_title'>Please Keep Safe your Secret Key for OTP recovery</h5>
                                        <div className="success_message text-center">
                                            <p>{ShowSecret}</p>
                                        </div>
                                        <h5 className='otp_title'>Scan QR with your Authenticator App and Enter OTP to verify your account</h5>
                                        <div className='d-flex justify-content-center'>
                                            <Image src={QR} fluid />
                                        </div>
                                        <Form.Group className="mt-3 d-flex otp_field" controlId="formBasicLastName">
                                            <Form.Control type="number" placeholder="Enter OTP"
                                                {...register("two_factor", {
                                                    required: "OTP is required",
                                                    minLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    }
                                                })}
                                                onWheel={(e) => e.target.blur()} />
                                            {/* {EmailDisabled ?
                                                <button className='primary_btn' disabled>Resend</button>
                                                :
                                                <button className='primary_btn' onClick={(e) => handleResetOTP(e, "email")}>Resend</button>
                                            } */}
                                        </Form.Group>
                                        {errors.two_factor && <small className='error_msg_class'>{errors.two_factor.message}</small>}
                                        {/* {EmailOtp ? <small className='error_msg_class'>{EmailOtp}</small> : ""} */}
                                        <div className="otp_btns mt-4">
                                            {/* <button type='submit' onClick={handleResetOTP} className='primary_btn' style={{ backgroundColor: "#143652" }}>Reset OTP</button> */}
                                            <button type='submit' className='primary_btn'>Verify</button>
                                        </div>
                                    </form>
                                    {/* <p className='resend_link'><span className='resend_otp' onClick={handleResetOTP}>Resend OTP</span></p> */}
                                    {/* <p className='resend_link'><span className='resend_otp'  onClick={handleResetOTP}>Resend OTP</span> within <span className='timer'>00:{counter} </span> seconds.</p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}
