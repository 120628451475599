import React, { useState, useEffect } from 'react'
import { STAKE_ABI } from '../../../contract/Stake'
import { PBMC_ABI } from '../../../contract/ERC20_ABI'
import Web3 from 'web3'
import { Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { updateALLStakerList, updatePBMCValue } from '../../../redux/reducer'
import axios from "axios"
function Stake() {
    const web3 = new Web3(window.ethereum);
    let WalletAddress = useSelector(state => state.walletBalance.walletAddress)
    const PBMCAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const PBMC = new web3.eth.Contract(PBMC_ABI, PBMCAddress)
    const dispatch = useDispatch()
    const StakeList = useSelector(state => state.user.updateStakerList)
    const togglePBMCAmount = useSelector(state => state.user.updatePBMCAmount)
    const accessToken = sessionStorage.getItem('accessToken')
    const { _id } = JSON.parse(sessionStorage.getItem('loggedInUserData'))
    // use form hook

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    // use form hook

    const [ApproveError, setApproveError] = useState(false)
    const [StakeShow, setStakeShow] = useState(false)
    const [ApproveLoading, setApproveLoading] = useState(false)
    const [StakeLoading, setStakeLoading] = useState(false)

    const [StakingValue, setStakingValue] = useState("100")
    const [duration, setDuration] = useState("0 months - 0")
    const [PBMC_Reward, setPBMC_Reward] = useState(0)

    const [OnApproved, setOnApproved] = useState("1")
    const [OnStake, setOnStake] = useState("2")
    const [ShowStakeLimit, setShowStakeLimit] = useState(false)


    const getDuration = watch('stake_month')
    useEffect(() => {
        if (getDuration !== '' && getDuration !== undefined) {
            setDuration(getDuration)
        }
    }, [getDuration])

    const d = duration.split("-")
    const months = d[0].split(' ')[0]
    const EthValue = StakingValue !== '' && (StakingValue * 10 ** 14).toString();


    // ==================================================== Staking Calculation =======================================================
    const userInputChange = watch('stake_amount')
    useEffect(() => {
        setStakingValue(userInputChange)
    }, [userInputChange])
    // ===================================================== GetMinimum Value ================================================================
    const [MinLimit, setMinLimit] = useState(0)
    const [MaxLimit, setMaxLimit] = useState(0)
    async function GetMinMaxValue() {
        const minAmount = await stake.methods.minStakeAmount().call()
        const minValue = (minAmount / 10 ** 14)
        setMinLimit(minValue)
        const maxAmount = await stake.methods.maxStakeAmount().call()
        const maxValue = (maxAmount / 10 ** 14)
        setMaxLimit(maxValue.toFixed())
    }

    useEffect(() => {
        GetMinMaxValue()
    }, [])

    // ===================================================== GetMinimum Value ================================================================
    // ==================================================== Staking Calculation =======================================================
    const userInput = watch('stake_amount')
    // ===================================================== COntract Methods ======================================================
    const ABI = STAKE_ABI;
    const ContractAddress = process.env.REACT_APP_STAKE_CONTRACT_ADDRESS;
    const stake = new web3.eth.Contract(ABI, ContractAddress);
    function CalculateAndConversion() {
        const StakeAmount = watch('stake_amount')
        const PBMC_WEI = (StakeAmount === undefined || StakeAmount === '' ? "0" : (StakeAmount * 10 ** 14).toString())
        const PBMC_Number = Number(PBMC_WEI).toLocaleString('fullwide', { useGrouping: false }) //convert exponential value in decimals
        if (WalletAddress !== null) {
            if (WalletAddress !== "NA") {
                const getDuration = watch('stake_month')
                if (getDuration !== '' && getDuration !== undefined) {
                    const d = getDuration.split("-")
                    const APY = d[1].trim() * 10
                    const stakeMonth = d[0].split(' ')[0]
                    stake.methods.calculateReward(PBMC_Number, (APY.toString()), (stakeMonth)).call().then(e => {
                        const val = (e / 10 ** 14)
                        setPBMC_Reward(val)
                    }).catch(e => {
                        console.log(e);
                    })
                }
            }
        }
    }
    const [MaxLimitError, setMaxLimitError] = useState(false)
    function stakeAmount() {
        const StakeAmount = watch('stake_amount')
        // ===================================================================================
        if (Number(StakeAmount) > MaxLimit) {
            setMaxLimitError(true)
            return
        } else {
            setMaxLimitError(false)
        }
        // ===================================================================================
        CalculateAndConversion()
    }
    // ===================================================== COntract Methods ======================================================



    // ===================================================== Stake Token ======================================================
    async function Stake_PBMC() {
        if (WalletAddress !== null) {
            if (WalletAddress !== "NA") {
               	                // =================================================== Check Stake Limit ====================================================
                                   const a = await PBMC.methods.allowance(WalletAddress, ContractAddress).call()
                                   const stakeVal = (a / 10 ** 14)
                                   const stakeValue = (userInput * 10 ** 14).toString()
                                   if (stakeVal >= userInput) {
                                       setStakeLoading(true)
                                       console.log(stakeValue);
                                       await stake.methods.stake(stakeValue, months).send({
                                           from: WalletAddress
                                       }).then(e => {
                                           console.log("e", e);
                                           storeStakeData()
                                           dispatch(updateALLStakerList(!StakeList))
                                           dispatch(updatePBMCValue(!togglePBMCAmount))
                                           setStakeShow(false)
                                           setStakeLoading(false)
                                           reset()
                                       }).catch(e => {
                                           console.log(e);
                                           setStakeLoading(false)
                                       })
                                   } else {
                                       console.log("You have to approved min 100 PBMC to Stake");
                                       setApproveError(true)
                                       setTimeout(() => {
                                           setApproveError(false)
                                       }, 3000);
                                   }
                                   // const result = await stake.methods.getUsersPlans().call({ from: WalletAddress })
                                   // stake.methods.getUsersPlans().call({ from: WalletAddress }).then(async e => {
                                   //     console.log({ e });
                                   // switch (months) {
                                   //     case "12":
                                   //         const _12month = e.userOneYearPlans.length;
                                   //         return stakeAPICall(_12month)
                                   //     case "6":
                                   //         const _6month = e.userSixMonthPlans.length;
                                   //         return stakeAPICall(_6month)
                                   //     case "3":
                                   //         const _3month = e.userThreeMonthPlans.length;
                                   //         return stakeAPICall(_3month)
                                   // }
                                   //     async function stakeAPICall(month) {
                                   //         const userInput = watch('stake_amount')
                                   //         checkApprove()
                                   //         if (month >= 5) {
                                   //             setShowStakeLimit(true)
                                   //             setTimeout(() => {
                                   //                 setShowStakeLimit(false)
                                   //             }, 5000);
                                   //         } else {
                                   //             setShowStakeLimit(false)
                                   //             const a = await PBMC.methods.allowance(WalletAddress, ContractAddress).call()
                                   //             const stakeVal = (a / 10 ** 14)
                                   //             const stakeValue = (userInput * 10 ** 14).toString()
                                   //             if (stakeVal >= userInput) {
                                   //                 setStakeLoading(true)
                                   //                 console.log(stakeValue);
                                   //                 await stake.methods.stake(stakeValue, months).send({
                                   //                     from: WalletAddress
                                   //                 }).then(e => {
                                   //                     dispatch(updateALLStakerList(!StakeList))
                                   //                     dispatch(updatePBMCValue(!togglePBMCAmount))
                                   //                     setStakeShow(false)
                                   //                     setStakeLoading(false)
                                   //                     reset()
                                   //                 }).catch(e => {
                                   //                     console.log(e);
                                   //                     setStakeLoading(false)
                                   //                 })
                                   //             } else {
                                   //                 console.log("You have to approved min 100 PBMC to Stake");
                                   //                 setApproveError(true)
                                   //                 setTimeout(() => {
                                   //                     setApproveError(false)
                                   //                 }, 3000);
                                   //             }
                                   //         }
                                   //     }
                                   // }).catch(e => {
                                   //     console.log(e);
                                   // })
                // =================================================== Check Stake Limit ====================================================
            } else {
                dispatch(updatePBMCValue(!togglePBMCAmount))
            }
        }
    }
    // ============================================== Check Approved Amount =========================================

    async function checkApprove() {
        const getApprovedAmount = await PBMC.methods.allowance(WalletAddress, ContractAddress).call()
        const approvedVal = (getApprovedAmount / 10 ** 14)
        if (userInput <= approvedVal) {
            setStakeShow(true)
        } else {
            setStakeShow(false)
        }
        return approvedVal
    }
    useEffect(() => {
        if (WalletAddress !== null) {
            if (WalletAddress !== "NA") {
                checkApprove()
            }
        }
    }, [userInput])

    // ===================================================== Stake Token ======================================================
    async function Approved_Stake() {
        if (WalletAddress !== null) {
            if (WalletAddress !== "NA") {
                setApproveLoading(true)
                try {
                    const tx = await PBMC.methods.approve(ContractAddress, EthValue).send({ from: WalletAddress })
                    setStakeShow(true)
                    setApproveLoading(false)
                } catch (error) {
                    setApproveLoading(false)
                }
            } else {
                dispatch(updatePBMCValue(!togglePBMCAmount))
            }
        }
    }

    // ==================================================
    const onSubmit = (data) => {
        if (OnStake === "Stake") {
            Stake_PBMC()
            setTimeout(() => {
                setOnStake("1")
            }, 100);
        }
    }
    const storeStakeData = async () => {
        const bodyData = {
            authId: _id,
            WalletAddress: WalletAddress,
            duration: Number(months),
            stakeAmount: userInput,
            reward: PBMC_Reward,
            start_Date: Date.parse(new Date()),
            end_Date: new Date(Date.parse(new Date()) + (1000 * 60 * 60 * 24 * 30 * Number(months))),
            stakeStatus: false
        }
        console.log("bodyData", bodyData);
        await axios.post(`${process.env.REACT_APP_BASE_URL}/funds/create-staking`, bodyData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            console.log(res);
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <>
            <div className="staking_content">
                <p className='text'>Stake Tokens</p>

                <form onSubmit={handleSubmit(onSubmit)} >

                    <div className="input_feild">
                        <div className='token_name'>PBMC</div>
                        <input min={MinLimit} max={MaxLimit} type="number" placeholder="0" className='form-control'
                            {...register("stake_amount", {
                                required: "Stake Amount is required",
                                min: {
                                    value: MinLimit,
                                    message: `Minimum Stake amount should be greater than or equal to ${MinLimit}.`
                                },
                                max: {
                                    value: MaxLimit,
                                    message: `Maximum Stake amount should be equal to ${MaxLimit}.`
                                },
                                onChange: () => stakeAmount()
                            })}
                        />
                    </div>
                    {errors.stake_amount && <small className='error_msg_class'>{errors.stake_amount.message}</small>}
                    <small className='text-secondary text-center d-block w-100'>**You have to approved {MaxLimitError ? <b>{`max ${MaxLimit}`}</b> : <b>{`min ${MinLimit}`}</b>} PBMC to Stake**</small>

                    <div className="staking_points_div">

                        <select className='form-select'
                            {...register("stake_month", {
                                required: "Stake Month is required",
                                onChange: () => stakeAmount()
                            })}
                        >
                            <option value="" defaultValue="0 months - 0">Select Duration</option>
                            <option value="3 months - 2.2 - 1">3 months - 2.2 APY</option>
                            <option value="6 months - 4.5 - 2">6 months - 4.5 APY</option>
                            <option value="12 months - 10 - 3">12 months - 10 APY</option>
                        </select>
                        {errors.stake_month && <small className='error_msg_class'>{errors.stake_month.message}</small>}

                        <div className="staking_points">
                            <p className='title'>Duration</p>
                            <p className='points'>{d[0]}</p>
                        </div>
                        <div className="staking_points">
                            <p className='title'>Estimated Reward</p>
                            <p className='points'>{PBMC_Reward} PBMC</p>
                        </div>
                        <div className="staking_points">
                            <p className='title'>APY</p>
                            <p className='points'>{d[1]} %</p>
                        </div>
                    </div>
                    {ShowStakeLimit && <small className='error_msg_class text-center d-block w-100'>**Staking only allowed 5 times in specific duration**</small>}
                    {ApproveError && <small className='error_msg_class text-center d-block w-100'>**You have to approved min {MinLimit} PBMC to Stake**</small>}
                    {/* button */}

                    <div className="staking_btn">
                        {StakeShow === true ?
                            StakeLoading ?
                                <button className='primary_btn' disabled>< Spinner variant='light' size='sm' /></button>
                                :
                                <button className='primary_btn' onClick={() => setOnStake("Stake")}>Stake PBMC</button>
                            : ""
                        }
                    </div>

                    {/* button */}

                </form >
                {
                    StakeShow === false ?
                        ApproveLoading ?
                            <button className='primary_btn w-100' disabled>< Spinner variant='light' size='sm' /></button>
                            :
                            MaxLimitError ?
                                <button className='primary_btn w-100' disabled>Approve</button>
                                :
                                <button className='primary_btn w-100' onClick={Approved_Stake}>Approve</button>
                        : ""
                }

            </div >
        </>
    )
}

export default Stake