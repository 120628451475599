import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import Cookies from 'js-cookie'
import Web3 from 'web3';
import { updatePBMCValue } from "../../../../redux/reducer";
import { useSelector, useDispatch } from 'react-redux';
import { usdAbi } from "../../../../contract/ERC20_ABI1";
import { PBMC_ABI } from '../../../../contract/ERC20_ABI';
import copy from "copy-to-clipboard";
function AllLendingRequest() {
    const dispatch = useDispatch()
    const WalletAddress = useSelector(state => state.walletBalance.walletAddress)
    const OpenMetamask = useSelector(state => state.user.updatePBMCAmount)
    const [UpdateData, setUpdateData] = useState(false)
    const [Loading, setLoading] = useState(NaN)
    const [checkCopy, setCheckCopy] = useState(NaN)
   

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);

        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);

        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);

        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

        };


        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [allLendingData, setAllLendingData] = useState([])
    const [loader, setLoader] = useState(true)
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allLendingData.length) : 0;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };



    //========== get lending details user start ===============//
    const accessToken = sessionStorage.getItem('accessToken')
    const getAllLendingData = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/funds/getAllDataLoan`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            
            setLoader(false)
            setAllLendingData(res.data.details.reverse())
        })
            .catch((err) => {
                console.log("admin lendign list err", err)
                setLoader(false)
            })
    }

    useEffect(() => {
        getAllLendingData()
    }, [UpdateData])

    //========== get lending details user start ===============//
    const web3 = new Web3(window.ethereum);
    const adminAddress = process.env.REACT_APP_OWNER_ADDRESS;
    const PBMC_Address = process.env.REACT_APP_CONTRACT_ADDRESS;
    const token = new web3.eth.Contract(PBMC_ABI, PBMC_Address);
    async function sendPBMCToken({ _id, person_address, pbmc_amount }, key) {
        if (WalletAddress !== "NA") {
            // const _id = target.getAttribute('data-id')
            // const index = target.getAttribute('data-value')
            setLoading(key)
            // const address = document.getElementsByClassName('walletAddress')[index].getAttribute('address')
            // const amount = document.getElementsByClassName('pbmcAmount')[index].getAttribute('amount')
           
            try {
                const pbmValue =  Math.floor(pbmc_amount * 10 ** 14).toString();
                
                const tx = await token.methods.transfer(person_address, pbmValue).send({ from: adminAddress })
                if (tx.transactionHash.length > 0) {
                    api(_id, tx.transactionHash, true)
                }
            } catch (err) {
                console.log(err);
                setLoading(NaN)
            }
        } else {
            dispatch(updatePBMCValue(!OpenMetamask))
        }
    }
   	    // =================================================== Token Transfer ===========================================================
           function api($id, $txnHash) {
            const body = {
                "transaction_hash": $txnHash,
                "id": $id,
            }
            axios.post(`${process.env.REACT_APP_BASE_URL}/funds/reciveLoanAdmin`, body,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(e => {
                    setUpdateData(!UpdateData)
                    setLoading(NaN)
                }
                ).catch(e => {
                    console.log(e)
                    setLoading(NaN)
                }
                )
        }
	    // ===================================== Send Collateral =====================================
        const sendCollateral = async (event, data) => {
            try {
                const _id = event.target.getAttribute('data-id');
                const index = event.target.getAttribute('data-value')
                setLoading(index)
                const receivedAmount = data.received_currency.toString()
                const amount = web3.utils.toWei(receivedAmount)
                console.log("receivedAmount", receivedAmount);
                const tx = await web3.eth.sendTransaction({ from: WalletAddress, to: data.person_address, value: amount })
                receiveCollateral(_id, tx.transactionHash)
                console.log({ tx })
            } catch (error) {
                setLoading(NaN)
                console.log(error);
            }
        }
        function receiveCollateral($id, $txnHash) {
            const body = {
                "id": $id,
                "transaction_hash": $txnHash,
            }
            console.log(body, accessToken);
            axios.post(`${process.env.REACT_APP_BASE_URL}/funds/reciveCollateral`, body,
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                }
              }).then(e => {
                setUpdateData(!UpdateData)
                setLoading(NaN)
              }
              ).catch(e => {
                console.log(e)
                setLoading(NaN)
              }
              )
          }
    // ====================================== COPY TRANSACTION ID ======================================
    const handleCopy = (transactionId, event, data) => {
        copy(transactionId);
       
        setCheckCopy(data)
       
        setTimeout(() => {
            setCheckCopy(NaN)
        }, 1000);
    }

    return (
        <>
            <div className="invoice_overview_section">
                <Container fluid className='px-0'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="invoice_overview_content">
                                <div className="invoice_title">
                                    <h6>Lending Request List</h6>
                                </div>
                                <div className="table_content">
                                    <div className="invoice_table">
                                        <TableContainer  >
                                            <Table aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Transaction Id</TableCell>
                                                        <TableCell>Borrower's wallet address</TableCell>
                                                        <TableCell>Collateral Amount</TableCell>
                                                        <TableCell>Collateral Currency</TableCell>
                                                        <TableCell>PBMC Send</TableCell>
                                                        <TableCell>Loan Term</TableCell>
                                                        <TableCell>APR</TableCell>
                                                        <TableCell>Yearly Interest</TableCell>
                                                        <TableCell>Lending Start Time</TableCell>
                                                        <TableCell>Lending End Time</TableCell>
                                                        <TableCell>Send Loan</TableCell>
                                                        <TableCell>Return Collateral</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        loader ?
                                                            <TableRow>
                                                                <TableCell colSpan={12} >
                                                                    <div className='text-center' style={{ height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <Spinner animation="border" variant="danger" />
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                            :
                                                            allLendingData.length > 0 ?
                                                                (rowsPerPage > 0
                                                                    ? allLendingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    : allLendingData
                                                                    ).map((e, i) => {
                                                                        return (
                                                                            <TableRow key={i}>
                                                                                <TableCell className='lending_transaction' transaction={i} onClick={(event) => handleCopy(e.transactionId, event, i)}>
                                                                                    {Web3.utils.isHex(e.transactionId) ? `${e.transactionId.slice(0, 4)}...${e.transactionId.slice(-4)}` : e.transactionId}
                                                                                    {checkCopy == i ? <span data-value={i} className='copy_text'>Copied</span> : ""}
                                                                                </TableCell>
                                                                                <TableCell className='walletAddress'>{e.person_address}</TableCell>
                                                                                <TableCell>{String(e.received_currency).charAt(1) == "e" ? Number(e.received_currency).toFixed(9).replace(/\.?0+$/,"") : e.received_currency}</TableCell>
                                                                                <TableCell><div style={{ textTransform: "uppercase" }}>{e.payment_type}</div></TableCell>
                                                                                <TableCell className='pbmcAmount'>{e.pbmc_amount ? e.pbmc_amount.toFixed(3) : "-"}</TableCell>
                                                                                <TableCell>{e.loanTerm}</TableCell>
                                                                                <TableCell>{e.apr_rate}</TableCell>
                                                                                <TableCell>{e.pbmc_percentage ? e.pbmc_percentage + " PBMC" : "-"} </TableCell>
                                                                                <TableCell>{e.createdAt?.slice(0, 10)}</TableCell>
                                                                                <TableCell>{e.recive_status == "success" ? e.updatedAt?.slice(0, 10) : "N/A"}</TableCell>
                                                                                <TableCell>
                                                                                    {e.recive_status == "success" ?
                                                                                        <a target='_blank' href={`https://etherscan.io/tx/${e?.reciveAdminHash}`}>
                                                                                            {`${e?.reciveAdminHash?.slice(0, 4)}...${e?.reciveAdminHash?.slice(-4)}`}</a>
                                                                                        :
                                                                                        i == Loading ?
                                                                                            <button className='btn btn-primary'><Spinner variant='light' size='sm' /></button>
                                                                                            :
                                                                                            <button className='btn btn-primary'onClick={() => sendPBMCToken(e, i)} style={{ padding: "2px 10px" }}>Send</button>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {/* {e.return_status == "pending" ? <button className='btn btn-primary btn-sm'>Send</button> : "Done"} */}
                                                                                    {
                                                                                        e.collateralStatus == "success" ?
                                                                                            <a target='_blank' href={`https://etherscan.io/tx/${e?.reciveAdminHash}`}>
                                                                                                {`${e?.reciveAdminHash?.slice(0, 4)}...${e?.reciveAdminHash?.slice(-4)}`}</a>
                                                                                            :
                                                                                            e.return_status === "pending" ? <button className='btn btn-primary' style={{ padding: "2px 10px" }} disabled>Send</button> :
                                                                                                i == Loading ?
                                                                                                    <button className='btn btn-primary'><Spinner variant='light' size='sm' /></button>
                                                                                                    :
                                                                                                    <button data-id={e._id} data-value={i} className='btn btn-primary' style={{ padding: "2px 10px" }} onClick={(event) => sendCollateral(event, e)}>Send</button>
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                :
                                                                <TableRow>
                                                                    <TableCell colSpan={12} ><h4 className='text-center' style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>No data found</h4></TableCell>
                                                                </TableRow>
                                                    }
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={12} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                            colSpan={12}
                                                            count={allLendingData.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: {
                                                                    'aria-label': 'rows per page',
                                                                },
                                                                native: true,
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AllLendingRequest