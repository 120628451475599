import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import { useSelector } from 'react-redux'
import TablePagination from '@mui/material/TablePagination';
import Web3 from 'web3'
import { STAKE_ABI } from "../../../../contract/Stake"
import axios from "axios"
function StakingHistory() {
    const [stakingData, setStakingData] = useState("")
    const [filterStating, setFilterStating] = useState("all")
    const web3 = new Web3(window.ethereum);
    let WalletAddress = useSelector(state => state.walletBalance.walletAddress);
    const STAKE_CONTRACT = process.env.REACT_APP_STAKE_CONTRACT_ADDRESS
    const STAKE = new web3.eth.Contract(STAKE_ABI, STAKE_CONTRACT)
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);

        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);

        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);

        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    function createData(Invoice, Request, Amount, Payment, Status, Action) {
        return { Invoice, Request, Amount, Payment, Status, Action };
    }
    const rows = [
        createData('0.003 ETH', "3"),
        createData('0.003 EUR', "3"),
        createData('1 USD ', "3"),
        createData('0.003 ETH', "3"),
        createData('0.003 ETH', "3"),
        createData('0.003 ETH', "3"),
        createData('1 USD', "3"),
        createData('1 USD', "3"),
        createData('1 USD', "3"),
        createData('1 USD', "3"),
        createData('1 USD', "3"),

    ];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - PBMCUnStake.length) : 0;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    const handleChange = ({ target: { value } }) => {
        setFilterStating(value)
    }
    const [PBMCUnStake, setPBMCUnStake] = useState([]);
    const loginUserData = sessionStorage.getItem('loggedInUserData')
    const [loginData, setLoginData] = useState(null)
    useEffect(() => {
        if (loginUserData) {
            setLoginData(JSON.parse(loginUserData))
        }
    }, [])
    const accessToken = sessionStorage.getItem('accessToken')
    const unStakingAPI = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/funds/getStaking?authId=${loginData._id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
            setPBMCUnStake(data.stakingData)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (loginData !== null) {
            unStakingAPI()
        }
    }, [filterStating, loginData])
    return (
        <>
            <div className="history_section ongoing p-0 pt-3">
                <Container fluid className='px-0'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="transaction_history">
                                <div className='title'>
                                    <h6>Staking History</h6>
                                    <div className="filter_input">
                                        <label htmlFor="">Filter</label>
                                        <Form.Select aria-label="Default select example" onChange={handleChange}>
                                            <option value="all">All</option>
                                            <option value="true">Staked</option>
                                            <option value="false">Unstaked</option>
                                        </Form.Select>
                                    </div>
                                </div>

                                <div className="history_table">
                                    <TableContainer  >
                                        <Table aria-label="custom pagination table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>S.No.</TableCell>
                                                    <TableCell>Stake Token</TableCell>
                                                    <TableCell>Duration</TableCell>
                                                    <TableCell>Reward</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Start Date</TableCell>
                                                    <TableCell>End Date</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    PBMCUnStake &&
                                                        PBMCUnStake.filter((filterEvent) =>
                                                            filterStating === "all" ? filterEvent
                                                                : filterStating == filterEvent.stakeStatus.toString()).length > 0
                                                        ? PBMCUnStake.filter((filterEvent) =>
                                                            filterStating === "all"
                                                                ? filterEvent
                                                                : filterStating == filterEvent.stakeStatus.toString()).map((event, index) => {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{index + 1}</TableCell>
                                                                            <TableCell>{event.stakeAmount} PBMC</TableCell>
                                                                            <TableCell>{event.duration} months</TableCell>
                                                                            <TableCell>{event.reward} PBMC</TableCell>
                                                                            <TableCell>{event.stakeStatus ? "Staked" : "Unstaked"}</TableCell>
                                                                            <TableCell>{new Date(event.start_Date).toLocaleString()}</TableCell>
                                                                            <TableCell>{new Date((event.end_Date)).toLocaleString()}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                        :
                                                        <TableRow>
                                                            <TableCell colSpan={7} ><h4 className='text-center' style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>No data found</h4></TableCell>
                                                        </TableRow>
                                                }
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={11} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                        colSpan={11}
                                                        count={PBMCUnStake.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: {
                                                                'aria-label': 'rows per page',
                                                            },
                                                            native: true,
                                                        }}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default StakingHistory