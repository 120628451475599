import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import ReCAPTCHA from "react-google-recaptcha";

function AdminLogin() {
  const navigate = useNavigate()

  const [emailErr, setEmailErr] = useState("")
  const [passwordErr, setPasswordErr] = useState("")
  const [emailErrShow, setEmailErrShow] = useState(false)
  const [passwordErrShow, setPasswordErrShow] = useState(false)
  const [loader, setLoader] = useState(false);
  const [showMsg, setShowMsg] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const [CaptchaHash, setCaptchaHash] = useState("")
  const [CaptchaError, setCaptchaError] = useState("")
  function onChange(value) {
    setCaptchaHash(value)
  }



  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

  //============ api res error handling start ===========//
  const email = watch('email')
  const password = watch('password')
  useEffect(() => {
    if (email) {
      setEmailErr("")
    }
  }, [email])

  useEffect(() => {
    if (password) {
      setPasswordErr("")
    }
  }, [password])

  const handleEmailErr = () => {
    setPasswordErr(" ")
    setPasswordErrShow(false)
  }
  const handlePassswordErr = () => {
    setPasswordErr(" ")
    setPasswordErrShow(false)
  }
  //============ api res error handling end ===========//



  //===========login api functionality start===============//
  const onSubmit = (data) => {
    if (CaptchaHash === '' || CaptchaHash === null) {
      setCaptchaHash(null)
      return
    }
    sessionStorage.setItem('login_type', "admin", { expires: 1 })
    setLoader(true)

    let formData = new FormData()
    formData.append("email", data.email)
    formData.append("password", data.password)
    formData.append("role", "admin")
    formData.append("captcha_token", CaptchaHash)


    axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/loginUser`, formData, {
      headers: {
        "Accept": "application/json, text/plain",
        'Content-Type': 'application/json'
      }
    }
    )
      .then((res) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)


        if (res.data.status === 200) {
          if (res.data.details.auth_2FA === 2) {
            sessionStorage.setItem('accessToken', res.data.token)
            setTimeout(() => {
              navigate('/dashboard/home')
            }, 3000)
          } 
          if(res.data.details.auth_2FA === 1) {
            setTimeout(() => {
              navigate('/2FA-authentication')
            }, 3000)
          }
          sessionStorage.setItem('loggedInUserData', JSON.stringify(res.data.details))

          setTimeout(() => {
            setShowMsg(true)
          }, 1000)
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false)
        }, 500)
        console.log("admin login err", err.response.data)
        if (err.response.status === 403) {
          setCaptchaError("Captcha Verification Failed")
        }
        if (err.response.data.status === 409) {
          setPasswordErrShow(true)
          setPasswordErr("Email and Password is wrong!")
        }
        if (err.response.data.status === 422) {
          setPasswordErrShow(true)
          setPasswordErr("Email and Password is wrong!")

        }
      })

  }
  //===========login api functionality end===============//


  return (
    <>
      {
        loader ? <div className="loader_wrap"><div className="lds-hourglass"></div></div> : <div></div>
      }
      <div className="login_section">
        <div className='text_bg'>
          <div className='text'>DEFI <br></br> FINANCE</div>
          <div className='text'>SECURE <br></br> TRUSTED  </div>
        </div>
        <Container>
          <Row className=''>
            <Col xl={5} lg={6} md={8} sm={12}>
              <div className='login_wrapper'>
                <div className='login_box'>
                  {
                    showMsg ?
                      <div className="success_message">
                        <p>Login successfully</p>
                      </div>
                      :
                      " "
                  }
                  <h4 className="mb-4 text-center">ADMIN LOGIN</h4>

                  <form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Group className="mt-3" controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="Email Address" onMouseDown={handleEmailErr}
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                          }
                        })}
                      />
                    </Form.Group>
                    {errors.email && <small className='error_msg_class'>{errors.email.message}</small>}
                    {emailErrShow ? <small className='error_msg_class'>{emailErr}</small> : ""}

                    <Form.Group className="mb-1 mt-3" controlId="formBasicPassword">
                      <div className='input_pwd_div'>
                        <Form.Control type={showPwd ? "text" : "password"} placeholder="Password" onMouseDown={handlePassswordErr}
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Minimum required password is 6 digit"
                            },
                            maxLength: {
                              value: 16,
                              message: "Maximum required password is 16 digit"
                            },
                            pattern: {
                              value: /^[^\s].+[^\s]/,
                              message: "Space not allowed"
                            },
                          })}
                        />
                        <div className='pwd_icon' onClick={() => { setShowPwd(!showPwd) }}>
                          {showPwd ? <FaEye /> : <FaEyeSlash />}
                        </div>
                      </div>
                    </Form.Group>
                    {errors.password && <small className='error_msg_class'>{errors.password.message}</small>}
                    {passwordErrShow ? <small className='error_msg_class'>{passwordErr}</small> : ""}

                    <div className="forgot_pwd_link">
                      <Link to="/forgot_password">Forgot Password?</Link>
                    </div>
                    <ReCAPTCHA
                      id='g-captcha'
                      size="normal"
                      sitekey="6LfQDIonAAAAAEZgNrCpzKqTppiuhwkXqETxzhT2"
                      onChange={onChange}
                    />
                    {CaptchaHash === null ? <small className='error_msg_class w-100 d-block text-end mt-2'>Captcha is required</small> : ''}
                    <small className='error_msg_class w-100 d-block text-center my-4'>{CaptchaError}</small>
                    <div className="d-grid mt-3">
                      <button className='primary_btn mt-2' type="submit">Login</button>
                    </div>
                  </form>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AdminLogin