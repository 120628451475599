import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Image, Button, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

export default function Login2FA() {
    const [Success, setSuccess] = useState(false)
    const [Error, setError] = useState(false)
    const [ShowSecret, setShowSecret] = useState('')
    const [Loader, setLoader] = useState(false)
    const navigation = useNavigate()

    const { _id, secret, auth_2FA } = JSON.parse(sessionStorage.getItem('loggedInUserData'))

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    // ======================================= Verify 2FA OTP =============================================
    const onSubmit = (data) => {
        setLoader(true)
        const bodyData = {
            "id": _id,
            "otpToken": data.two_factor
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/2FA_Auth-login`, bodyData).then(e => {
            if (e.data.status === 200) {
                sessionStorage.setItem('accessToken', e.data.token)
                setSuccess(true)
                reset()
                setTimeout(() => {
                    setSuccess(false)
                    navigation('/dashboard/home')
                }, 2000);
            }
            setLoader(false)
        }
        ).catch(({ response: { data } }) => {
            setLoader(false)
            console.log(data)
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
        )
    }

    // ======================================= Secrect Key =========================================
    function ShowSecretKey() {
        setShowSecret(secret)
    }

    // ========================================= Check 2FA Enable =======================================
    useEffect(() => {
        if (auth_2FA === 2) {
            navigation('/dashboard/home')
        }
    }, [])

    return (
        <>
            <div className="login_section">
                <div className='text_bg'>
                    <div className='text'>DEFI <br></br> FINANCE</div>
                    <div className='text'>SECURE <br></br> TRUSTED  </div>
                </div>
                <Container>
                    <Row className=''>
                        <Col xl={5} lg={7} md={8} sm={12}>
                            <div className='login_wrapper'>
                                <div className='login_box'>
                                    {Success &&
                                        <div className="success_message text-center">
                                            <p>{'2FA Verification successfully done!'}</p>
                                        </div>
                                    }
                                    {
                                        Error &&
                                        <div className="account_err text-center">
                                            <p>{'Please enter correct OTP!'}</p>
                                        </div>
                                    }
                                    {
                                        ShowSecret !== '' &&
                                        <div className="success_message text-center">
                                            <p>{ShowSecret}</p>
                                        </div>
                                    }
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h5 className='otp_title'>Enter OTP to verify 2-Factor Authentication</h5>
                                        <Form.Group className="mt-3 d-flex otp_field" controlId="formBasicLastName">
                                            <Form.Control type="number" placeholder="Enter OTP"
                                                {...register("two_factor", {
                                                    required: "OTP is required",
                                                    minLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "OTP must be 6 digits"
                                                    }
                                                })}
                                                onWheel={(e) => e.target.blur()} />
                                        </Form.Group>
                                        {errors.two_factor && <small className='error_msg_class'>{errors.two_factor.message}</small>}
                                        <div className="otp_btns mt-4">
                                            {
                                                Loader ?
                                                    <button type='submit' disabled className='primary_btn'><Spinner variant='light' size='sm'/></button>
                                                    :
                                                    <button type='submit' className='primary_btn'>Verify</button>
                                            }
                                        </div>
                                    </form>
                                    {/* <div className='text-center my-2'>
                                        <Button variant="link" onClick={ShowSecretKey}>Recover OTP</Button>
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}
