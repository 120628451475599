import React from 'react'
import PBMC_history from './PBMC_history'
import Currency_history from './Currency_history'

function P2PExchange() {
  return (
    <div>
        <Currency_history/>
        <PBMC_history/>
    </div>
  )
}

export default P2PExchange