import React, { useState, useEffect } from 'react'
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import Cookies from 'js-cookie'
import Indicator from '../../../style/images/indicator.svg'
import WaveHand from '../../../style/images/wave-hand-icon.svg'
import AdminDashboard from './AdminDashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Charts from './Chart'
import { useGetCurrencyBySymbolQuery } from '../../../redux/CurrencyAPI'
import { useGetPBMCBySymbolQuery } from '../../../redux/PBMC_currencyAPI'

function MainDashboard() {
  const { data: EUR_TO_USD } = useGetCurrencyBySymbolQuery('EURUSDT')
  const { data: PBMC_currency } = useGetPBMCBySymbolQuery()

  const WallBalance = useSelector((a) => a.walletBalance.tokenBalance)
  const login_type = sessionStorage.getItem('login_type')
  const accessToken = sessionStorage.getItem('accessToken')
  const loginUserData = sessionStorage.getItem('loggedInUserData')
  const userProfile = useSelector((state) => state.user.userProfile)

  const [BuyerDashboardData, setBuyerDashboardData] = useState()
  const [SupplierDashboardData, setSupplierDashboardData] = useState()
  const [InvestorDashboardData, setInvestorDashboardData] = useState()
  const [loginData, setLoginData] = useState(null)
  const [chartData, setChartData] = useState([])

  const [graphStatus, setGraphStatus] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loginUserData) {
      setLoginData(JSON.parse(loginUserData))
    }
  }, [])

//==================================================
  const getCapitalValues = (symbol)=>{
    let PBMC_value = PBMC_currency && PBMC_currency.PBMCoin[0].coin_price
    let EUR_value = EUR_TO_USD && EUR_TO_USD.price
    switch (symbol) {
      case 'USD': 
          return (WallBalance * PBMC_value).toFixed(2)
      case 'EUR': 
          return (WallBalance * (PBMC_value * (1 / EUR_value))).toFixed(2)
  }
}
//==================================================

  //================ buyer dashboard details api ===========================
  useEffect(() => {
    if (loginData) {
      getBuyerDetails()
      getSupplierDetails()
      getInvestorDetails()
    }
  }, [loginData])


  const getBuyerDetails = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/dashboardBuyer/${loginData._id}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
       

        setBuyerDashboardData(res.data)
        if (login_type === "buyer") {
          let chartData = []
          chartData[0] = res.data.TotalInvoiceRejected
          chartData[1] = res.data.TotalInvoice
          chartData[2] = res.data.TotalInvoicePending
          chartData[3] = res.data.TotalInvoiceApproved
          setChartData(chartData)

          setGraphStatus(res.data.InvoiceLatestData)
          setLoading(false)
        }

      })
      .catch((err) => {
        console.log("buyer dashboard err---", err)
      })
  }

  //================ supplier dashboard details api ===========================
  const getSupplierDetails = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/dashboardData/${loginData._id}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
       
        setSupplierDashboardData(res.data)


        if (login_type === "supplier") {
          let chartData = []
          chartData[0] = res.data.TotalInvoiceRejected
          chartData[1] = res.data.TotalInvoice
          chartData[2] = res.data.TotalInvoicePending
          chartData[3] = res.data.TotalInvoiceApproved
          setChartData(chartData)

          setGraphStatus(res.data.InvoiceLatestData)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("supplier dashboard err---", err)
      })
  }

  //================ investor dashboard details api ===========================
  const getInvestorDetails = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/kyc/dashboardInvestor`, { collateral_per: 25, id: loginData._id }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
       
        setInvestorDashboardData(res.data)
        if (login_type === "investor") {
          let chartData = []
          chartData[0] = res.data.BurnNFT
          chartData[1] = res.data.TotalInvoiceApproved
          chartData[2] = res.data.DepositNFT
          setChartData(chartData)

          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("investor dashboard err---", err)
      })
  }
  //==============================



  return (
    <>
      {login_type !== "admin" ?
        <section className='dashboard__home'>
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div className='welcome__banner'>
                  <h3>Hello <span>{userProfile && userProfile.first_Name}</span> <span>{userProfile && userProfile.last_Name}</span> <Image src={WaveHand} fluid loading="lazy"/>, Welcome to <b style={{ textTransform: "capitalize" }}>{login_type}</b> Dashboard</h3>
                </div>
              </Col>
              <Col lg={6} className='right_available_fund'>
                <div className='available_fund_box'>
                  <h5>Available Funds</h5>
                  <div className='available_fund_val'>
                    <h2>{WallBalance}</h2>
                    <span>(PBMC)</span>
                  </div>
                </div>
              </Col>
              <Col lg={6} className='left_available_fund'>
                <div className='available_fund_box'>
                  <div className='inner_box'>
                    <h5>Breakdown</h5>
                    <div className='breakdown'>
                      <div className='wrapper'>
                        <div>Capital (in USD)</div>
                        <div>${Number(getCapitalValues('USD')).toLocaleString("en-US")}</div>
                      </div>
                      <div className='wrapper'>
                        <div>Capital (in EUR)</div>
                        <div>€{Number(getCapitalValues('EUR')).toLocaleString("en-US")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className='financial_Health my-4'>
                  <div className='financial__health'>
                    <h5>Financial Health</h5>
                    <Row className='justify-content-center'>
                      <Col lg={4}>
                        {
                          loading ? <div className='text-center py-5'><Spinner animation="border" variant="danger" /></div>
                            :
                            login_type === "investor" ?
                              (chartData.BurnNFT === 0 && chartData.TotalInvoiceApproved === 0 && chartData.DepositNFT === 0) ?
                                <h3 className='text-center py-5'>Make your first transaction to see your Financial Health</h3>
                                :
                                <Charts chartData={chartData} labelData={['Burn NFT', 'Invoice Funded', 'All Invoices',]} />
                              :
                              (graphStatus.length === 0) ?
                                <h3 className='text-center py-5'>Make your first transaction to see your Financial Health</h3>
                                :
                                <Charts chartData={chartData} labelData={['Rejected Invoice', 'Total Invoice', 'Pending Invoice', 'Approved Invoice',]} />
                        }
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>

                <div className='invoices_box'>
                  <h5>Invoices</h5>
                  {
                    login_type === "investor" ?
                      <div className='all_invoice'>
                        <div className='all_invoices'>
                          <div>Invoice Funded</div>
                          <div>{InvestorDashboardData && InvestorDashboardData.TotalInvoiceApproved} Invoices</div>
                        </div>
                        <div className='total_value mt-4'>
                          <div>All Invoices</div>
                          <div>{InvestorDashboardData && InvestorDashboardData.DepositNFT}</div>
                        </div>
                        <div className='total_value mt-4'>
                          <div>Burn NFT</div>
                          <div>{InvestorDashboardData && InvestorDashboardData.BurnNFT}</div>
                        </div>
                      </div>
                      :
                      <div className='all_invoice'>
                        <div className='all_invoices'>
                          <div>Approved Invoices</div>
                          <div>
                            {login_type === "buyer" ?
                              BuyerDashboardData && BuyerDashboardData.TotalInvoiceApproved
                              :
                              login_type === "supplier" ? SupplierDashboardData && SupplierDashboardData.TotalInvoiceApproved
                                : "0"
                            } Invoices</div>
                        </div>
                        <div className='total_value mt-4'>
                          <div>Available Payment</div>
                          <div>$ {login_type === "buyer" ?
                            BuyerDashboardData && BuyerDashboardData.TotalInvoiceAmount
                            :
                            login_type === "supplier" ? SupplierDashboardData && SupplierDashboardData.TotalInvoiceAmount
                              : "0"
                          }</div>
                        </div>
                      </div>
                  }

                </div>

              </Col>
            </Row>
          </Container>
        </section>
        :
        <AdminDashboard />
      }
    </>
  )
}

export default MainDashboard


